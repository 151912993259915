html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    font-family: $fontopensans;

    // pixelperfect
    // min-height: 3574px;
    // position: relative;
    // &:after {
    //     content: '';
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     background: url('/pp_index.jpg') 50% 0 no-repeat;
    //     opacity: .5;
    // }
    // .wrapper {
    //     z-index: 100 !important;
    // }
}