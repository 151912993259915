.region {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;

    &__img {
        margin: 0 10px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
    }
    &__link {
        color: #ffffff;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;

        &:hover {
            text-decoration: none;
        }
    }
    &__name {
        @extend .region;
    }

    &__selection-box {
        position: absolute;
        width: 600px;
        top: 40px;
        background: #ffffff;
        font-size: 14px;
    }
}
