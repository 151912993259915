.pagetitle {
    padding: 5px 0;
    line-height: 35px;
    font-family: $fontcuprum;
    font-weight: bold;
    font-size: 32px;
    color: #3b434d;
    margin-bottom: 10px;

    &--orange {
        color: #e65e26;
    }

    &--specials {
        margin-bottom: 5px;
    }
}
