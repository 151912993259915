.copyright {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin-right: 22px;

    &__link {
        position: relative;
        line-height: 25px;
        color: #9e9e9e;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
        &::after {
            content: '';
            /* position: absolute; */
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            /* right: -30px; */
            top: 0;
            width: 30px;
            height: 30px;
            line-height: 25px;
            background: url(../img/16.png) center center/cover no-repeat;
        }
    }
}
