.maintitle {
    background: #ebebeb;
    border-radius: 10px 0 0 0 / 10px 0 0 0;
    height: 50px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;

    &__title {
        padding: 0 10px;
        font-family: $fontcuprum;
        font-size: 20px;
        color: #3b434d;
        font-weight: bold;
    }

    &__details {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        width: auto;
        padding: 0 5px;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}
