.logo {

    &__link, &__img {
        display: block;
        width: 191px;
    }
    &--header {
        width: 230px;
        margin: 16px 0; 
    }
    &--vertical &__img {
        max-width: 103px;
        max-height: 67px;
        display: block;
        margin-right: 20px;
    }
}
