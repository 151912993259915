.breadcrumbs {
    margin-bottom: 20px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 11px;
    line-height: 15px;
    position: relative;
    z-index: 2;

    &__link {
        color: #929292;
        text-decoration: underline;
        margin-right: 20px;
        position: relative;

        &:hover {
            text-decoration: none;
        }

        &:after {
            content: '';
            position: absolute;
            right: -20px;
            top: 2px;
            width: 20px;
            height: 15px;
            display: block;
            background: url("data:image/gif;base64,R0lGODlhCAAMANUwAOfn5/7+/paWlaOjoqampdfX1tjY2MzMy+zs7LOzs/39/c/PzuDg4M3Nzbi4uOLi4dzc3NbW1tTU1LKysefn5pubmpeXlsnJyKqqqfLy8q2trPz8/MfHxtnZ2aWlpNDQ0PHx8e3t7cPDw/v7+7u7uqKiocfHx6qqqqysq/j4+Lq6ubKysp6enZiYl5ycm8LCwf///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMwNjcgNzkuMTU3NzQ3LCAyMDE1LzAzLzMwLTIzOjQwOjQyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Qjk0ODQ2RkI0NkIyMTFFNzhCMzFBODZCRjVEOUU3QkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Qjk0ODQ2RkM0NkIyMTFFNzhCMzFBODZCRjVEOUU3QkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCOTQ4NDZGOTQ2QjIxMUU3OEIzMUE4NkJGNUQ5RTdCQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCOTQ4NDZGQTQ2QjIxMUU3OEIzMUE4NkJGNUQ5RTdCQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACH5BAEAADAALAAAAAAIAAwAAAZEQI7gACsWWxHCywh7VDoolcIIcElWq5ExxFqoUCljZmB6YYyg0uV1KiJYH4cmTHEVJokNjOGCnEgBRwYeIkwmFg1MMEEAOw==") center center / 4px 6px no-repeat;
        }
    }
    &__page {
        color: #929292;
    }
}
