.header.scrolling {
    position: fixed;
    width: 100%;
    top: 0;
    min-height: 60px;
    height: 60px;

    .logo--header {
        margin: 5px 0;
    }
    .slogan__text {
        display: none;
    }
    .region__img {
        margin-left: 35px;
    }
    .region__name {
        font-size: 0;
        color: transparent;
        width: 1px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            font-size: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 5px;
            background: url('../img/region_arrow_bottom@2x.png') 50% 50% / cover no-repeat;
        }
    }
    .floatmenu {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }
    .search--float {
        display: block;
    }
    .special {
        display: none;
    }
}
.header.inner {
    @extend .header.scrolling;
}
