.filterservice {

    &__title {
        line-height: 18px;
        color: #5597d1;
        height: 53px;
        text-decoration: none;

        &::before {
            content: '';
            position: absolute;
            width: 51px;
            height: 51px;
            border-radius: 50%;
            left: -10px;
            top: -5px;
            border: 5px solid #ffffff;
            background: url("../img/icon_leftbar_innermenu_03@2x.png") 50% 50% / 25px 25px no-repeat #5597d1;
        }
    }
    &__block {
        background: #ebebeb;
        padding-top: 15px;
    }
    &__inputtext {
        width: 100%;
        font-size: 13px;
        color: #929292;
        font-family: $fontopensans;
        margin-bottom: 10px;
    }
    &__inputcase {
        padding: 0 10px;
        margin-bottom: 20px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        &inner {
            width: 33.33333%;
            float: left;
            // padding-bottom: 10px;
        }
    }
    &__labelcase {
        width: 35%;
    }
    &__toggle {
        width: 30%;
    }
    &__labelbig {
        cursor: pointer;
        padding: 0 5px;
        padding-top: 26px;
        line-height: 25px;
        background: url('../img/icon_filterservice_01@2x.png') 50% 0 / 26px 26px no-repeat;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        width: 35%;
        font-family: $fontcuprum;
        text-align: center;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: #5597d1;
        filter: grayscale(1);

        &--active {
            filter: grayscale(0);
        }

        &--old1 {
            background-image: url('../img/icon_filterservice_01@2x.png');
            order: 1;
        }
        &--old2 {
            background-image: url('../img/icon_filterservice_02@2x.png');
            order: 3;
        }
        &--male1 {
            background-image: url('../img/icon_filterservice_03@2x.png');
            order: 1;
        }
        &--male2 {
            background-image: url('../img/icon_filterservice_04@2x.png');
            order: 3;
        }
    }
}
