.comments {
    &__block {
        margin-top: 40px;
    }

    &__header {
        background: #d7d7d7;
        border-radius: 20px 0 0 0;
        padding: 4px 12px 3px 12px;
        position: relative;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
    }

    &__title {
        display: inline-block;
        width: calc(80% - 0.25em);
        margin: 0;
    }

    &__add-link {
        display: block;
        font-size: 15px;
        font-family: $fontopensans;
        vertical-align: middle;
        text-decoration: none;

        &-text {
            display: inline-block;
            color: #929292;
            text-decoration: none;
            border-bottom: 1px dotted #929292;
        }
        &:hover {
            .comments__add-link-text {
                color: #5597d1;
                border-bottom: 1px dotted #5597d1;
            }

            .comments__ico {
                background: url("../img/comments/commentsIco-hover.png");
            }
        }
    }

    &__ico {
        display: inline-block;
        background: url("../img/comments/commentsIco.png");
        width: 40px;
        height: 40px;
        vertical-align: middle;
    }

    &__comment {
        border-radius: 20px 0 0 0;
        border: 1px solid #f3f3f3;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;
        margin-right: 3px;
    }

    &__comment--level2 {
        width: calc(100% - 90px);
        margin-left: 87px;
        position: relative;
        margin-right: 3px;

        &:before {
            position: absolute;
            content: '';
            display: block;
            top: -10px;
            left: 50px;
            width: 20px;
            height: 10px;
            background: url("../img/comments/commentsBefore.png");
        }
    }

    &__comment-text {
        padding: 10px;
        font-size: 15px;
        font-family: $fontopensans;
        color: #404040;
    }

    &__comment-info {
        background: #f3f3f3;
        padding: 10px;
    }

    &__comment-name {
        font-size: 17px;
        font-family: $fontcuprum;
        color: #929292;
        display: inline-block;
        padding-right: 20px;
    }

    &__comment-date {
        display: inline-block;
        font-size: 13px;
        font-family: $fontopensans;
        color: #929292;
    }

    &__comment-reply {
        font-size: 13px;
        font-family: $fontopensans;
        color: #a2a1a1;
        text-decoration: none;
        border-bottom: 1px dotted #a2a1a1;
        display: inline-block;
        position: relative;
        float: right;
        margin-right: 31px !important;
        cursor: pointer;

        &:after {
            position: absolute;
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            right: -30px;
            background: url("../img/comments/commentsReply.png");
            top: 50%;
            transform: translate(0, -50%);
        }

        &:hover {
            color: #004d7b;
            border-bottom: 1px dotted #004d7b;

            &:after {
                background: url("../img/comments/commentsReply-hover.png");
            }
        }
    }

    &__show-all {
        font-size: 14px;
        font-family: $fontopensans;
        color: #004d7b;
        text-align: center;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 47%, #fff 100%);
        padding: 15px 0;
        top: -66px;
        position: relative;
        cursor: pointer;
    }

    &__add-back-log {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 20;
    }

    &__add-popup {
        display: none;
        z-index: 21;
        position: fixed;
        top: 50%;
        background: #d7d7d7;
        width: 710px;
        padding: 15px;
        box-sizing: border-box;
    }

    &__add-popup-form {
        &:before,
        &:after {
            content: '';
            clear: both;
            display: block;
        }
    }

    &__add-popup-form-group--small {
        float: left;
        width: calc(32% - 15px);
        margin-right: 15px;
    }

    &__add-popup-form-group--big {
        float: left;
        width: calc(68%);
    }

    &__add-popup-form-input {
        width: 100%;
        padding: 10px;
        border-radius: 20px;
        border: none;
        box-sizing: border-box;
        margin-bottom: 15px;
        font-size: 13px;
        font-family: $fontopensans;
        color: #929292;
        background-color: #fff;
    }

    &__add-popup-form-textarea {
        resize: none;
        width: 100%;
        height: 140px;
        border: none;
        border-radius: 20px;
        padding: 15px;
        box-sizing: border-box;
        font-size: 13px;
        font-family: $fontopensans;
        color: #929292;
        background-color: #fff;
    }

    &__add-popup-form-submit-block {
        position: relative;
        margin-top: 10px;
    }

    &__add-popup-form-submit {
        font-size: 13px;
        font-family: $fontopensans;
        color: #595959;
        text-decoration: none;
        border: none;
        background: none;
        cursor: pointer;
        vertical-align: middle;
    }

    &__add-popup-form-submit {
        &:hover {
            text-decoration: underline;
        }
    }

    &__add-popup-ico {
        vertical-align: middle;
        display: inline-block;
        background: url("../img/comments/commentsIcoPopup.png");
        width: 40px;
        height: 40px;
        margin-left: 5px !important;
    }

    &__add-popup-close {
        position: absolute;
        top: -40px;
        right: 0;
        width: 28px;
        background: url("../img/comments/popupclose.png");
        cursor: pointer;
        height: 28px;
    }

    &__add-error {
        font-size: 13px;
        color: #f00;
    }

    &__accept-rules {
        color: #595959;
        margin-top: 10px;

        input[type=checkbox] {
            -webkit-appearance: checkbox;
            -moz-appearance: checkbox;
            appearance: checkbox;
            vertical-align: top;
        }

        label {
            width: 90%;
            display: inline-block;
        }
    }
}