.usefulinfo {

    &__title {
        color: #3b434d;
        font-family: $fontcuprum;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 25px;
    }
    &__list {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &__item {
        width: calc(33.33333% - 15px);
        padding-left: 15px;
        margin-bottom: 20px;
        background: url("data:image/gif;base64,R0lGODlhBQAJAMQaAFhfaJufpFFYYePk5v39/a2wtGZsdIGGjcLFyIyQlunq6z1FT5qepO/w8IKIjrG1uNze4LK1uWhudkdPWE1UXaOnq1deZ0dOVzxETjtDTf///wAAAAAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMwNjcgNzkuMTU3NzQ3LCAyMDE1LzAzLzMwLTIzOjQwOjQyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REJDQTMxNkY0NTQyMTFFN0FENjBBQTczMDkwM0VDREYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REJDQTMxNzA0NTQyMTFFN0FENjBBQTczMDkwM0VDREYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQkNBMzE2RDQ1NDIxMUU3QUQ2MEFBNzMwOTAzRUNERiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQkNBMzE2RTQ1NDIxMUU3QUQ2MEFBNzMwOTAzRUNERiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACH5BAEAABoALAAAAAAFAAkAAAUhoHQQmmZkSdkAGVMqQlaUTzZp0LUgA4VFGkumUnIESpoQADs=") 2px 5px no-repeat;
    }
    &__link {
        font-family: $fontopensans;
        color: #3b434d;
        font-size: 13px;
        text-decoration: underline;
        line-height: 16px;
        display: block;

        &:hover {
            text-decoration: none;
        }
    }
}
