.wrapper--blind {
    padding: 20px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.blind {
    width: 100%;
    background: #fff;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    display: none;
    line-height: 20px;
    padding: 0;
    margin: 0;
    font-size: 20px !important;
    padding-bottom: 30px;
    font-family: "open_sansregular";
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    &__item {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        font-weight: bold;
        justify-content: space-between;
        min-height: 100px;
    }
    &__buttons {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
    }
    &__link {
        padding: 0;
        margin: 0;
        height: 30px;
        border: 1px solid #000000;
        border-radius: 3px;
        line-height: 30px;
        overflow: hidden;
        padding: 0 8px;
        text-decoration: none !important;
        color: #000000;

        &--fontsize100 {
            font-size: 14px;
        }
        &--fontsize102 {
            font-size: 17px;
        }
        &--fontsize104 {
            font-size: 20px;
        }
        &--fontsize106 {
            font-size: 23px;
        }
        &--fontsize108 {
            font-size: 26px;
        }
        &--fontsize110 {
            font-size: 29px;
        }
    }
    &__buttons {
        padding: 0;
        margin: 0;
    }
}

.imgoff {
    display: none !important;
}

.font100 {
    font-size: 100%;
}

.font102 {
    font-size: 102%;
}

.font104 {
    font-size: 104%;
}

.font106 {
    font-size: 106%;
}

.font108 {
    font-size: 108%;
}

.font110 {
    font-size: 110%;
}

.default {
}

.whiteblack,
.whiteblack:hover {
    background-image: none;
    background: #ffffff;
    color: #000000;
}

.blackwhite,
.blackwhite:hover {
    background-image: none;
    background: #000000;
    color: #ffffff;

    .blind__link {
        border-color: #ffffff;
    }
}

.blackwhite:hover a,
.whiteblack:hover a {
    text-decoration: underline;
}