$organization-list-selector: 'organization-list';

.#{$organization-list-selector} {

    &__headline {
        height: 44px;
        margin-bottom: 15px;
        padding: 0 18px 0 56px;
        box-sizing: border-box;
        position: relative;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        background: #ebebeb;
        z-index: 2;

        &:before {
            content: '';
            position: absolute;
            width: 49px;
            height: 49px;
            border-radius: 50%;
            left: -10px;
            top: -8px;
            border: 5px solid #ffffff;
            background: url(../img/icon_leftbar_innermenu_01@2x.png) center/50% 50% no-repeat #004d7b;
        }
    }

    &__headline-text {
        width: 900px;
        margin-right: 20px;
        font-family: $fontcuprum;
        font-size: 20px;
        font-weight: bold;
        color: #004d7b;
        overflow: hidden;
    }

    &__item {
        border: 1px solid #ebebeb;
        background: #fff;
        font-family: $fontcuprum;
        margin-bottom: 10px;
        position: relative;
        padding-right: 30px;
        display: flex;
        flex-direction: row;
        text-decoration: none;

        &:hover {
            border-color: #d2dee9;
        }

        &-img {
            max-width: 110px;

            &-placeholder {
                width: 110px;
                height: 73px;
                background: #ebebeb;

                .#{$organization-list-selector}__item:hover & {
                    background: #d2dee9;
                }
            }

            &-container {
                margin: -1px 9px -1px -1px;
            }
        }

        &-title {
            height: 29px;
            margin: 5px 0 6px;
            overflow: hidden;

            &-text {
                display: table-cell;
                height: 29px;
                vertical-align: middle;
                font-size: 17px;
                line-height: 14px;
                color: #3b434d;
                text-decoration: none;

                .#{$organization-list-selector}__item:hover & {
                    color: lighten(#3b434d, 10%);
                }
            }
        }

        &-description {
            height: 29px;
            overflow: hidden;
            font-family: $fontopensans;
            font-size: 13px;
            line-height: 14px;
            color: #3b434d;
        }

        &-arrow {
            display: block;
            width: 14px;
            height: 14px;
            position: absolute;
            top: 50%;
            right: 4px;
            margin-top: -7px;
            background: url(../img/icon_leftbar_title_arrow1.png) no-repeat;
        }

        &-badge {
            left: -1px;
            bottom: 6px;
        }
    }

    &__grid {
        margin: 10px 0;
    }

    &__side-menu {
        z-index: 2;
        position: relative;
        background: #ffffff;
        margin-top: 3px;

        &-item {
            border-bottom: 1px solid #ebebeb;
            position: relative;

            $menuItem: &;

            &-arrow {
                background: url(../img/blockslide_sprite.png) 0 -9px no-repeat;
                position: absolute;
                right: 5px;
                top: 10px;
                display: block;
                width: 15px;
                height: 9px;
                cursor: pointer;

                #{$menuItem}.active & {
                    background-position: 0 0;
                }
            }

            &.active ul {
                display: block;
            }
        }

        &-link {
            color: #004d7b;
            text-decoration: none;
            font-size: 16px;
            padding: 8px 25px 8px 8px;
            display: block;
            line-height: 19px;
            font-family: $fontcuprum;
            font-weight: normal;

            &:hover {
                background: #d2dee9;
            }
        }
    }

    &__side-submenu {
        display: none;
        background: #f7f7f7;

        .organization-list__side-menu-item.active & {
            display: block;
        }

        &-item {
        }

        &-link {
            color: #9b9b9b;
            text-decoration: none;
            font-size: 15px;
            padding: 8px 8px 8px 15px;
            display: block;
            line-height: 19px;
            font-family: $fontcuprum;
            font-weight: normal;
            position: relative;

            &:before {
                position: absolute;
                content: '>';
                left: 5px;
                top: 6px;
                font-size: 17px;
            }

            &:hover {
                background: #004d7b;
                color: #fff;
            }
        }
    }
}