.filterservice__toggle {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    
    .toggle {
        height: 24px;
    }
    input[type=checkbox]{
    	height: 0;
    	width: 0;
    	visibility: hidden;
        position: absolute;
        left: 0;
        z-index: -1000;
    }
    label.filterservice__labeltoggle {
    	cursor: pointer;
    	text-indent: -9999px;
    	width: 30%;
        margin: 0 2%;
    	height: 22px;
    	background: #ffffff;
    	display: block;
    	border-radius: 100px;
    	position: relative;
        border: 1px solid #d7d7d7;
        overflow: hidden;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        order: 2;
        margin-bottom: 10px;
    }
    label.filterservice__labeltoggle:after {
    	content: '';
    	position: absolute;
    	top: 2px;
    	left: 3px;
    	width: 18px;
    	height: 18px;
    	background: #5597d1;
    	border-radius: 90px;
    	transition: .25s;
    }
    input:checked + label.filterservice__labeltoggle {
        background: #ffffff;
    }
    input:checked + label.filterservice__labeltoggle:after {
    	background: #5597d1;
    }
    input:checked + label.filterservice__labeltoggle:after {
    	left: calc(100% - 3px);
    	transform: translateX(-100%);
    }
}
