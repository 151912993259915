.leftbar {
    width: 18.7%;
    margin-right: 3.1%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    z-index: 2;

    &__title {
        padding-left: 58px;
        height: 45px;
        background: #ebebeb;
        border-radius: 25px 0 0 25px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        font-family: $fontcuprum;
        font-size: 20px;
        color: #004d7b;
        font-weight: bold;
        flex-wrap: wrap;
        position: relative;

        &--popularservice {
            padding-left: 10px;
            border-radius: 0;
        }

        &--popular-specializations {
            padding-left: 10px;
            border-radius: 0;
            font-size: 18px;
        }

        &--adjacent-specialization {
            text-decoration: none;
            height: 54px;
            margin-bottom: 10px;
            &:before {
                position: absolute;
                left: 2px;
                top: 2px;
                display: block;
                content: '';
                background: #fff;
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }

    &__subtitle {
        background: #d7d7d7;
        border-radius: 10px 0 0 0 / 10px 0 0 0;
        padding: 10px;
        line-height: 20px;
        color: #3b434d;
        font-size: 17px;
        font-family: $fontcuprum;
        font-weight: bold;
        margin-bottom: 20px;
    }

    &__item {
        position: relative;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        &::before {
            content: '';
            position: absolute;
            width: 51px;
            height: 51px;
            border-radius: 50%;
            left: -10px;
            top: -8px;
            border: 5px solid #ffffff;
        }
        &--search::before {
            background: url("../img/icon_leftbar_search@2x.png") 50% 50% / 26px 26px no-repeat #004d7b;
        }
        &--innermenu_01::before {
            background: url("../img/icon_leftbar_innermenu_01@2x.png") 50% 50% / 23px 33px no-repeat #004d7b;
        }
        &--popularservice::before {
            visibility: hidden;
        }
    }
    &__arrow {
        padding: 5px 8px;
        transform: rotate(0deg);
        cursor: pointer;
        &.active {
            transform: rotate(90deg);
        }
    }

    &__item-caption {
        line-height: 20px;
        color: #3b434d;
        font-size: 17px;
        font-family: $fontcuprum;
        font-weight: bold;
        margin-bottom: 10px;
    }

}
