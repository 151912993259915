.holidays {
    padding-bottom: 20px;

    &__item {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 10px;
        flex-wrap: nowrap;
        text-decoration: none;
        color: #3b434d;
        align-items: center;

        &:last-child {
            padding: 0;
            border: 0;
            margin: 0;
        }
    }
    &__imgcase {
        border: 3px solid #3b434d;
        border-radius: 50%;
    }
    &__item:hover &__imgcase {
        border-color: #e65e26;
    }
    &__img {
        border-radius: 50%;
        width: 54px;
        height: 54px;
    }
    &__textcase {
        padding-left: 10px;
        font-size: 13px;
        color: #3b434d;
        text-decoration: none;
        line-height: 15px;
        text-align: left;
    }
    &__item:hover &__textcase {
        color: #e65e26;
    }
}
