body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
}

a {
    outline: 0;
    border: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img {
    border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
    font-style: normal;
    font-weight: normal;
}

ol,
ul,
nav,
menu {
    list-style: none;
}

caption,
th {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

q:before,
q:after {
    content: ''
}

abbr,
acronym {
    border: 0;
}

header,
footer,
nav,
aside {
    display: block;
}

input,
textarea,
button {
    outline: 0;
    border: 0;
    -webkit-appearance: none;
    appearance: none;
}

input:active,
textarea:active {
    outline: 0;
    border: 0;
}

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

input,
label,
select,
button,
textarea {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: 0;
    line-height: 1;
    font-size: 13px;
    font-family: Arial;
}

input:focus {
    outline: 0;
}

input,
textarea {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type=checkbox],
input[type=radio] {
    width: 13px;
    height: 13px;
}

input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
}

::-webkit-search-decoration {
    display: none;
}

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
    overflow: visible;
    width: auto;
}

::-webkit-file-upload-button {
    padding: 0;
    border: 0;
    background: 0;
}

textarea {
    vertical-align: top;
    overflow: auto;
}

select[multiple] {
    vertical-align: top;
}

