.banner {
    border-radius: 10px 0 0 0 / 10px 0 0 0;

    img {
        border-radius: 10px 0 0 0 / 10px 0 0 0;
        max-width: 100%;
    }

    &--950x155 {

        @media screen and (max-width: 1220px) {
            display: none;
        }
    }
    &--230x400 {
        padding-bottom: 20px;
    }
}
