.specialization-filter {
    &__title {
        margin-bottom: 20px;
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__person-link {
        margin: 15px 0;
    }
}