.opinion {

    &__container {
        float: right;
        width: 33%;
        margin-left: 40px;
        background: #ebebeb;
        padding: 15px 10px;
        box-sizing: border-box;
        border-radius: 20px 0 0 0;

        &:before,
        &:after {
            display: block;
            clear: both;
            content: '';
        }
    }

    &__title {
        font-size: 20px;
        font-family: $fontcuprum;
        color: #004d7b;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    &__text {
        font-size: 14px;
        font-family: $fontopensans;
        color: #404040;
        line-height: 1.714;
        margin-bottom: 10px;
        &:before,
        &:after {
            display: block;
            clear: both;
            content: '';
        }
    }

    &__person-info {
        width: calc(100% - 72px);
        vertical-align: top;
        float: left;
    }

    &__person-img {
        margin-right: 10px;
        border-radius: 50%;
        width: 62px;
        height: 62px;
        float: left;
    }

    &__person-name {
        font-size: 17px;
        font-family: $fontcuprum;
        color: #004d7b;
        line-height: 1.2;
        margin: 0 0 5px;
    }

    &__person-job {
        font-size: 13px;
        font-family: $fontopensans;
        color: #a2a1a1;
        line-height: 1.2;
        margin-bottom: 10px !important;
    }
}