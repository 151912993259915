.videorow {
    min-height: 155px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 16px;

    &__item {
        margin-right: 10px;

        &:last-child {
            margin: 0;
        }
        @media screen and (max-width: 1000px) {
            width: 33.33333%;
        }
    }
    &--smi {
        flex-direction: column;
    }
    &--smi &__item {
        margin: 0px;

        &:first-child {
            margin-bottom: 15px;
        }
    }
}
