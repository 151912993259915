.selectedfilter {
    margin-bottom: 30px;

    &__wrap {
        margin-bottom: 10px;
    }
    &__title {
        background: #d7d7d7;
        border-radius: 10px 0 0 0 / 10px 0 0 0;
        padding: 10px;
        line-height: 20px;
        color: #3b434d;
        font-size: 17px;
        font-family: $fontcuprum;
        font-weight: bold;
    }
    &__listcase {
        background: #ebebeb;
        padding: 13px 10px;
        font-family: $fontcuprum;
        font-size: 15px;
    }
    &__link {
        padding-left: 18px;
        display: block;
        margin-bottom: 8px;
        color: #3b434d;
        text-decoration: none;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjkzMzc4RjU1NDc2NDExRTdBRjREQ0I2QUMwNzRBQjlGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjkzMzc4RjU2NDc2NDExRTdBRjREQ0I2QUMwNzRBQjlGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTMzNzhGNTM0NzY0MTFFN0FGNERDQjZBQzA3NEFCOUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTMzNzhGNTQ0NzY0MTFFN0FGNERDQjZBQzA3NEFCOUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz66Cy//AAAAxElEQVR42kzQvw7BUBTH8ZJOGHgGryC18gD6EAaJjU3QjRiINB1MBpvNn8ZksSMS7yKCRPie5NekJ/mk9zTn3nPuzURR5Cga6MJTfkGIvSVZ/RwjRh15qWGHSVJoJ/VxxFQbP2jhjh58V+0s1ljhjRu2KGCOjhVWVLjEC4HyJmZae8mMFl+1TKKcuoNjJ151CZtpo5OeGCBnbXF2NYMVtlFU/kNJN7cIrfCAEYaoplov9LXniZMZAj3TCQ+xta+nc/4CDAD+8yk54NNhWwAAAABJRU5ErkJggg==") 2px 50% / 10px 10px no-repeat;

        &:hover {
            color: #e65e26;
        }
    }
    &__reset {
        padding-left: 18px;
        display: block;
        color: #3b434d;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
