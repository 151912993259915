.calend {
    width: 230px;
    text-decoration: none !important;
    text-align: center;

    table {
        width: 210px !important;
        padding-bottom: 10px;
    }

    a {
        text-decoration: none !important;
    }
    &__link {
        text-decoration: none;
        width: 22px !important;
        height: 22px !important;
        line-height: 22px !important;
        display: block;
        margin: auto;

        &:hover {
            background: #eb914e;
            border-radius: 50%;
            color: #ffffff !important;
        }
    }
}
