
.uslugi {

    .search-result {
        margin-bottom: 30px;
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
        li {
            line-height: 20px;
            font-size: 15px;
            padding: 4px 0;
            a {
                color: #3b434d;
                &:hover {
                    color: #5597d1;
                }
            }
        }
    }

    .search-result-2 {
        width: calc(50% - 15px);
        display: inline-block;
        box-sizing: border-box;
        margin: 10px 5px;
        &__title {
            font-size: 20px;
            font-weight: bold;
            color: #3b434d;
            margin-bottom: 10px;
            font-family: "cuprum", Impact, "Charcoal CY", sans-serif;
        }
        &__price {
            font-size: 15px;
            color: #1a1916;
            margin-bottom: 10px;
            font-family: "cuprum", Impact, "Charcoal CY", sans-serif;
            span {
                color: #929292;
                font-size: 13px;
            }
        }
        &__text {
            font-size: 15px;
            line-height: 24px;
            color: #404040;
            font-family: "opensans", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
        }
        &__more {
            color: #004d7b;
            font-size: 15px;
            line-height: 22px;
            text-decoration: underline;
            font-family: "cuprum", Impact, "Charcoal CY", sans-serif;
        }
        &__more:hover {
            color: #5597d1;
        }
        &__title-clinik {
            color: #004d7b;
            font-size: 17px;
            line-height: 22px;
            color: #3b434d;
            margin: 10px 0;
            font-weight: bold;
            font-family: "cuprum", Impact, "Charcoal CY", sans-serif;
        }
    }

    .pagetitle {
        color: #5597d1;
    }
    .filterservice {
        margin-bottom: 35px;

        textarea {
            width: 100%;
            padding: 0 5px;
            box-sizing: border-box;
            background: #fff;
            text-align: left;
            border-radius: 15px;
            height: 115px;
            line-height: 30px;
            border: 1px solid #d7d7d7;
            font-family: "opensans", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 11px;
        }
        &__text {
            font-size: 13px;
            line-height: 20px;
            color: #929292;
            padding: 0 12px 5px;
        }
    }
    .blue {
        background: #5597d1;
        &:hover {
            background: #004d7b;
        }
    }
    .selectedline {
        background: #ebebeb;
        margin-bottom: 0px;
        padding-left: 55px;
        color: #3b434d;
        font-size: 17px;
        line-height: 28px;
        font-weight: normal;
        padding-bottom: 20px;
    }
    .selectedline__item {
        background: url("../img/icon_filter_blue_26x26@2x.png") 4px 7px/13px 13px no-repeat;
        padding-right: 5px;
    }
}

.postbody {

    &__imgleft {
        margin-right: 9px;
        max-width: 227px;
        width: calc(50% - 10px);
        display: inline-block;
    }
    &__imgright {
        margin-left: 9px;
        max-width: 227px;
        width: calc(50% - 10px);
        display: inline-block;
    }
    &__title-uslugi {
        font-size: 20px;
        font-weight: bold;
        color: #3b434d;
        margin-bottom: 10px;
        font-family: "cuprum", Impact, "Charcoal CY", sans-serif;
    }
    &__najdeno {
        font-size: 17px;
        line-height: 28px;
        color: #3b434d;
        font-family: "cuprum", Impact, "Charcoal CY", sans-serif;
    }
    &__title-blue {
        font-size: 25px;
        line-height: 30px;
        color: #5597d1;
        font-family: "cuprum", Impact, "Charcoal CY", sans-serif;
        font-weight: 700;
        margin-bottom: 30px;
    }

}

.stoim-uslug {
    font-size: 13px;
    width: 100%;
    & td:first-child {
        padding-left: 0;
    }
    & td:last-child {
        padding-right: 0;
    }
}

.greybox {

    font-size: 15px;
    line-height: 22px;
    color: #1a1916;
    background: #ebebeb;
    margin-bottom: 40px;
    padding: 10px;
    margin-left: 10px;

    &__param {
        display: inline-block;
        color: #929292;
        font-size: 13px;
    }

    &__item {
        display: inline-block;
    }
    li:nth-child(3) &__param, li:nth-child(3) &__item, li:nth-child(4) &__param, li:nth-child(4) &__item {
        display: block;
    }
}

.single-usluga {

    &__personal-title {
        color: #3b434d;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 5px;
        margin-left: 10px;
    }

    .protivopokazania {
        font-size: 13px;
        line-height: 20px;

        li {
            font-size: 13px;
            line-height: 20px;
        }
    }
}

.uslugi--pokazania {
    font-family: "opensans", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
    font-size: 13px;
    line-height: 20px;
    position: relative;
    overflow: hidden;
    max-height: none;
    padding-top: 13px;
    li {
        font-size: 13px;
        line-height: 20px;
    }
    li span {
        margin-right: 10px;
    }
}

.uslugi--pokazania.closed {
    max-height: 260px;

    &:after {
        background: rgba(0, 0, 0, 0) linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%) repeat scroll 0 0;
        bottom: 0;
        content: "";
        display: block;
        height: 100px;
        left: 0;
        position: absolute;
        right: 0;
    }
}

.uslugi--pokazania--handler {
    text-align: center;
    font-family: "opensans", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
    font-size: 13px;
    color: #004d7b;
    padding-top: 3px;
    margin-bottom: 29px;
    cursor: pointer;
}

.uslugifilter {
    margin-bottom: 30px;

    .active {
        height: 0;
        overflow: hidden;
        padding: 0;
    }
    &__wrap {
        margin-bottom: 10px;
    }
    &__title {
        background: #d7d7d7;
        border-radius: 10px 0 0 0 / 10px 0 0 0;
        padding: 10px;
        line-height: 20px;
        color: #3b434d;
        font-size: 17px;
        font-family: $fontcuprum;
        font-weight: bold;
    }
    &__listcase {
        background: #ebebeb;
        padding: 13px 10px;
        font-family: $fontcuprum;
        font-size: 15px;
        line-height: 24px;
        height: auto;
        overflow: inherit;

        label {
            font-size: 15px;
            line-height: 24px;
            width: 100%;
            color: #3b434d;
        }
        span {
            display: inline-block;
            width: calc(100% - 23px);
        }
        ul li ul {
            margin-left: 15px;
            color: #929292;
        }
        ul li ul label {
            color: #929292;
            font-size: 14px;
            line-height: 20px;
        }

        input[type=checkbox] {
            -moz-appearance: checkbox;
            -webkit-appearance: checkbox;
            appearance: checkbox;
            margin-right: 10px;
            display: inline-block;
            vertical-align: top;
            margin-top: 5px;
        }
    }
    &__link {
        padding-left: 18px;
        display: block;
        margin-bottom: 8px;
        color: #3b434d;
        text-decoration: none;

        &:hover {
            color: #e65e26;
        }
    }
    &__reset {
        padding-left: 18px;
        display: block;
        color: #3b434d;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
