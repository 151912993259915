.mainlinks {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &__item {
        white-space: nowrap;
        word-wrap: normal;
        border-right: 1px solid #9e9e9e;
        padding: 0 8px;

        &:last-child {
            border: 0;
            padding-right: 0;
        }
    }
    &__link {
        color: #9e9e9e;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
