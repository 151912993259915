.trailernews {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @media screen and (max-width: 1220px) {
        width: 100%;
    }

    &__imgcase {
        margin-right: 15px;
    }
    &__imgc {
        width: 110px;
        max-width: 110px;
        height: 72px;
        max-height: 72px;
    }

    &__imgcase:hover + &__textcase &__title {
        color: #5597d1;
    }
    &__textcase {
        border-top: 1px solid #ececec;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        width: calc(100% - 110px - 15px);
        justify-content: space-between;
    }
    &__title {
        font-family: $fontcuprum;
        font-size: 17px;
        display: block;
        text-decoration: none;
        color: #3b434d;
        margin-top: 5px;
        margin-bottom: 10px;

        &:hover {
            color: #5597d1;
        }
    }
    &__details {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        font-size: 13px;
        width: 100%;
        justify-content: space-between;
        height: 14px;
        line-height: 14px;
        flex-wrap: wrap;
    }
    &__left {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        min-height: 14px;
        line-height: 14px;
        margin-bottom: 5px;
    }
    &__date {
        margin-right: 20px;
        color: #929292;
    }
    &__category {
        color: #e65e26;
        text-decoration: none;
        margin-right: 20px;
        height: 14px;
        line-height: 14px;
        background: url("../img/icon_category@2x.png") 0 -7px / 30px 60px no-repeat;
        padding-left: 23px;

        &:hover {
            color: #eb914e;
            background: url("../img/icon_category@2x.png") 0 -37px / 30px 60px no-repeat;
        }
    }
    &__region {
        color: #e65e26;
        text-decoration: none;
        margin-right: 20px;
        background: url("../img/icon_regionmark.png") 0 0 / 10px 28px no-repeat;
        padding-left: 15px;
        height: 14px;
        line-height: 14px;
        position: relative;
        z-index: 1;

        &:hover {
            color: #eb914e;
            background-position: 0 -14px;
        }
    }
    &__right {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        color: #929292;
        height: 14px;
        line-height: 14px;
    }
    &__views {
        margin-right: 20px;
        background: url("../img/icon_views@2x.png") left center / auto 12px no-repeat;
        padding-left: 23px;
        height: 14px;
        line-height: 14px;
    }
    &__comments {
        background: url("../img/icon_comments@2x.png") left center / auto 15px no-repeat;
        padding-left: 18px;
        height: 14px;
        line-height: 14px;
    }
}
