.search {

    &--static {
        height: 45px;
        background: #ebebeb;
        border-radius: 25px 0 0 25px;
        padding-left: 50px;
        padding-right: 0;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-bottom: 40px;
        position: relative;
    }
    &--filter {
        padding: 0 5px;
        width: 180px;
    }
    &--float {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 30px;
        padding: 0 10px;
    }

    &__inputcase {
        position: relative;

        &--float {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;

            form {
                height: auto;;
            }
        }
    }

    &--static &__iconzoom {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        background: url('../img/icon_zoom_search@2x.png') 0 0 / cover no-repeat;
        border-radius: 0 25px 25px 0 / 0 25px 25px 0;
        cursor: pointer;
        overflow: hidden;
        display: none;
    }
    &--filter &__iconzoom {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        background: url('../img/icon_news_titl@2x.png') 0 -60px / 30px 90px no-repeat #e65e26;
        border-radius: 15px;
        cursor: pointer;
        overflow: hidden;

        &:hover {
            background-color: #eb914e;
        }
    }
    &--float &__iconzoom {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        background: url('../img/icon_news_titl@2x.png') 0 -60px / 30px 90px no-repeat #e65e26;
        border-radius: 15px;
        cursor: pointer;
        overflow: hidden;

        &:hover {
            background-color: #eb914e;
        }
    }

    input,
    &__input {
        background: #ffffff;
        height: 30px;
        line-height: 25px;
        border-radius: 15px;
        padding: 0 12px;
        width: calc(100% - 24px);
        color: #929292;
        opacity: 1;
        font-size: 11px;

        &::-webkit-input-placeholder {
            color: #929292;
            opacity: 1;
        }
        &::-moz-placeholder {
            color: #929292;
            opacity: 1;
        }
        &:-moz-placeholder {
            color: #929292;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: #929292;
            opacity: 1;
        }
        &:focus::-webkit-input-placeholder {
            color: transparent;
            opacity: 1;
        }
        &:focus::-moz-placeholder {
            color: transparent;
            opacity: 1;
        }
        &:focus:-moz-placeholder {
            color: transparent;
            opacity: 1;
        }
        &:focus:-ms-input-placeholder {
            color: transparent;
            opacity: 1;
        }
    }

    &--static input:focus + &__iconzoom,
    &--static &__input:focus + &__iconzoom {
        display: block;
    }
}
