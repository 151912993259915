.timeseparator {
    -webkit-animation: timeseparator 1s ease;
    animation: timeseparator 1s ease;
}

@-webkit-keyframes timeseparator {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes timeseparator {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
