$entity-badge-selector: 'entity-badge';

.#{$entity-badge-selector} {

    &__badge {
        position: absolute;
        bottom: 0;
        left: -10px;
        height: 36px;
        line-height: 30px;
        padding-right: 8px;
        max-width: calc(100% - 18px);
        text-overflow: ellipsis;
        color: #fff;
        background-image: url(../img/specials/badge-middle.png);
        font-family: $fontcuprum;
        font-size: 18px;

        &:before {
            position: absolute;
            left: -8px;
            top: 0;
            content: '';
            display: block;
            height: 100%;
            width: 8px;
            background-image: url(../img/specials/badge-start.png);
        }
        &:after {
            position: absolute;
            right: -10px;
            top: 0;
            content: '';
            display: block;
            height: 100%;
            width: 10px;
            background-image: url(../img/specials/badge-end.png);
        }

        &--orange {
            background-position: 0 0;

            &:before,
            &:after {
                background-position: 0 0;
            }
        }

        &--green {
            background-position: 0 -36px;

            &:before,
            &:after {
                background-position: 0 -36px;
            }
        }

        &--gray {
            background-position: 0 -72px;

            &:before,
            &:after {
                background-position: 0 -72px;
            }
        }

        &--blue {
            background-position: 0 -108px;

            &:before,
            &:after {
                background-position: 0 -108px;
            }
        }

        &-text {
            overflow: hidden;
            width: 100%;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}