$specials-selector: 'special-items';

.#{$specials-selector} {

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 5px -10px 5px 0;
    }

    &__item {
        width: 220px;
        box-sizing: border-box;
        border-radius: 10px 0 0 0;
        border: 1px solid #ebebeb;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 15px 10px;
        color: #000000;
        text-decoration: none;

        &:hover {
            border-color: #d2dee9;
        }
    }

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #ffffff;
        font-family: $fontcuprum;
        font-size: 13px;
        background-color: #656565;
        padding: 10px;
        margin-left: -1px;
        margin-right: -1px;
        margin-top: -1px;

        @extend .left_border_radius;

        &--specials {
            background-color: #e65e26;

            .#{$specials-selector}__item:hover & {
                background-color: lighten(#e65e26, 10%);
            }
        }

        &--exhibition {
            background-color: #53b02e;

            .#{$specials-selector}__item:hover & {
                background-color: lighten(#53b02e, 10%);
            }
        }

        &--school {
            background-color: #929292;

            .#{$specials-selector}__item:hover & {
                background-color: lighten(#929292, 10%);
            }
        }

        &--conference {
            background-color: #5597d1;

            .#{$specials-selector}__item:hover & {
                background-color: lighten(#5597d1, 10%);
            }
        }
    }

    &__badge {
        &--specials {
            @extend .#{$entity-badge-selector}__badge--orange;
        }

        &--exhibition {
            @extend .#{$entity-badge-selector}__badge--green;
        }

        &--school {
            @extend .#{$entity-badge-selector}__badge--gray;
        }

        &--conference {
            @extend .#{$entity-badge-selector}__badge--blue;
        }
    }

    &__item-content {
        padding: 10px;
        display: block;
    }

    &__item-images {
        position: relative;
        display: block;
    }

    &__image {
        max-width: 100%;
        display: block;
    }

    &__organization-icon {
        max-width: 64px;
        position: absolute;
        top: 12px;
        right: 12px;
        border-radius: 50%;
        display: block;
    }

    &__description {
        font-family: $fontcuprum;
        font-size: 15px;
        padding: 5px 0;
        display: block;
        margin: 0 -3px 0 -3px;
    }

    &__horizontal-rule {
        display: block;
        margin: 0 10px;
        border: none;
        border-bottom: 1px solid #ebebeb;
    }

    &__organization-name {
        font-family: $fontcuprum;
        font-size: 13px;
        color: #004d7b;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 40px;
        padding: 5px 20px;
        text-align: center;
        box-sizing: border-box;
    }

    &__organization-address {
        font-family: $fontcuprum;
        font-size: 13px;
        background: #ebebeb;
        color: #929292;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 40px;
        padding: 5px 26px;
        overflow: hidden;
        text-align: center;
        box-sizing: border-box;

        .#{$specials-selector}__item:hover & {
            background: #d2dee9;
        }
    }
}

$specials-page-selector: 'special-page';

.#{$specials-page-selector} {
    &__article {
        margin-bottom: 40px;
        padding-bottom: 10px;
        border-bottom: none;

        &:before,
        &:after {
            display: block;
            clear: both;
            content: '';
        }

        h2 {
            @extend %h2;
        }

        h3 {
            @extend %h3;
        }

        p {
            @extend %p;
        }

        ul {
            @extend %ul;
        }

        ol {
            @extend %ol;
        }

        table {
            @extend %table;

            .mobileth {
                display: none;
            }
        }

        blockquote {
            @extend %blockquote;
        }

        .col3__item {
            display: inline-block;
            width: calc(33% - 0.25em - 10px);
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }

        .col2__item {
            display: inline-block;
            width: calc(50% - 0.25em - 10px);
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__description {
        display: block;
        font-family: $fontcuprum;
        font-size: 13px;

        &-type {
            display: inline-block;
            color: #ffffff;
            font-weight: 600;
            padding: 8px;

            &--specials {
                @extend .#{$specials-selector}__title--specials;
            }

            &--exhibition {
                @extend .#{$specials-selector}__title--exhibition;
            }

            &--school {
                @extend .#{$specials-selector}__title--school;
            }

            &--conference {
                @extend .#{$specials-selector}__title--conference;
            }
        }

        &-dates {
            display: inline-block;
            padding: 8px;
        }

        &-organization-name {
            display: block;
            color: #004d7b;
            margin: 16px 0;
        }

        &-organization-address {
            display: block;
            color: #929292;
            margin: 16px 0;
        }

        &-text {
            font-size: 15px;
        }
    }

    &__similar {
        display: flex;
        flex-direction: row;
        margin: 0 -10px;
    }

    &__subtitle {
        font-family: $fontcuprum;
        font-size: 25px;
        color: #3b434d;
        font-weight: 600;
    }
}