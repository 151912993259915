.footer {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex: 0 0 auto;
    padding-top: 20px;
    background: #3b434d;
    color: #9e9e9e;
    font-size: 14px;

    &__item {
        padding-bottom: 20px;
    }
    &__right {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        align-content: flex-end;
    }
    &__logo {
        width: 104px;
        margin-right: 18px;
        padding-top: 12px;

        &-img {
            max-width: 100%;
            height: auto;
        }
    }
}
