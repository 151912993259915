.adjacent-specialization {
    &__item {
        &:hover {
            background: #f0f0f0;
        }
    }

    &__data {

    }

    &__title {
        height: 20px;
        overflow: hidden;
        font-size: 13px;
        font-weight: bold;
        color: #929292;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__value {
        height: 29px;
        font-size: 15px;
        line-height: 14px;
        font-weight: bold;
        color: #3b434d;
        overflow: hidden;
    }

    &__list {
        &:before {
            display: none;
        }

        margin: 20px 0;
    }
}