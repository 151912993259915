.specialization {
    display: block;

    &__title {
        color: #3b434d;
        a {
            text-decoration: none;
            color: #3b434d;
        }
        &::before {
            content: '';
            position: absolute;
            width: 51px;
            height: 51px;
            border-radius: 50%;
            left: -10px;
            top: -8px;
            border: 5px solid #ffffff;
            background: url("../img/icon_leftbar_innermenu_02@2x.png") 50% 50% / 20px 24px no-repeat #3b434d;
        }
    }
    &__list {
        width: 100%;
        margin-top: 5px;
        position: relative;
        z-index: 5;
        height: 0;
        overflow: hidden;
        transition: all .5s ease;
        background: #ffffff;

        &.active {
            height: auto;
            overflow: auto;
            transition: all .5s ease;
        }
    }
    &__item {
        border-bottom: 1px solid #ebebeb;
    }
    &__link {
        color: #3b434d;
        text-decoration: none;
        font-size: 15px;
        padding: 8px;
        line-height: 19px;
        display: block;
        font-family: $fontcuprum;
        font-weight: normal;

        &:hover {
            color: #004d7b;
            background: #d2dee9;
        }
    }
}
