.grid-dis-col-1 .organization-info__administration-content {
    padding-left: 0;
}

.grid-dis-col-1 .personals__item {
    height: auto;
}

.grid-dis-col-1 .personals__details {
    height: auto;
}

.grid-dis-col-1 .personals__name--wide {
    height: 63px;
}

.grid-dis-col-1 .personals__description--wide {
    padding: 5px 12px 5px 25px;
}

.grid-dis-col-1 .personals__category--wide {
    padding-left: 25px;
    background-position-x: 3px;
}

.grid-dis-col-33 .vrach-info {
    min-height: 170px;
}

.grid-dis-col-33 .vrach-dostig {
    display: block;
    margin: 6px 0;
}

.grid-dis-col-33 .vrach-dostig img {
    display: inline-block;
    vertical-align: middle;
}

.grid-dis-col-33 .vrach-dostig .vrach-dostig-text {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);
    color: #929292;
    font-size: 13px;
    margin-left: 10px;
}

.vrach-title-dolznosti {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
}

.vrach-content-dolznosti {
    background: #ebebeb;
    padding: 10px;
}

.vrach-content-dolznosti-50 {
    width: calc(50% - .25em - 10px);
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    @media screen and (max-width: 768px) {
        width: 100%;
        display: block;
    }
}

.vrach-content-dolznosti-map {
    text-align: center;
}

.vrach-content-dolznosti-adress {
    color: #929292;
    font-size: 13px;
    text-align: center;
    font-family: $fontcuprum;
    margin: 5px auto 15px auto;
}

.vrach-content-dolznosti-grafik-title {
    color: #3b434d;
    font-size: 15px;
}

.vrach-content-dolznosti-50 table td {
    width: 22%;
    text-align: center;
    color: #3b434d;
    font-size: 15px;
    padding: 3px 0;
}

.vrach-content-dolznosti-50 table td:last-child {
    width: 46%;
}

.rach-content-dolznosti-grafik-prev {
    display: inline-block;
    width: calc(50% - .25em);
    font-size: 13px;
    color: #929292;
    margin: 5px 0;
    cursor: pointer;
}

.rach-content-dolznosti-grafik-next {
    display: inline-block;
    width: calc(50% - .25em);
    font-size: 13px;
    color: #929292;
    text-align: right;
    margin: 5px 0;
    cursor: pointer;
}

.rach-content-dolznosti-grafik-prev::before {
    content: '';
    display: inline-block;
    position: relative;
    background: url("../img/icon_leftbar_title_arrow3-p.png") no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
}

.rach-content-dolznosti-grafik-next::after {
    content: '';
    display: inline-block;
    position: relative;
    background: url("../img/icon_leftbar_title_arrow3.png") no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
}

.rach-content-dolznosti-grafik-button {
    background: #d7d7d7;
    color: #929292;
    width: 100%;
    display: block;
    padding: 10px 0;
    text-align: center;
}

.vrachi-svedenia-btn {
    font-size: 13px;
    color: #929292;
    text-decoration: underline;
    margin-left: 15px;
    display: block;
}

.vrachi-svedenia-btn:after {
    content: '';
    display: inline-block;
    position: relative;
    background: url("../img/icon_title_arrow-bottom.png") no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
    margin-left: 10px;
}

.vrachi-border {
    border-left: 1px solid #ebebeb;
}

$doctorsPageSelector: '.doctors';

#{$doctorsPageSelector} {
    &__photo {
        overflow: hidden;
        border-radius: 50%;
    }
}