.region-selection {
    &__box {
        -webkit-box-shadow: 0 0 10px 0 #a8a8a8;
        box-shadow: 0 0 10px 0 #a8a8a8;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &:before {
            position: absolute;
            content: '';
            top: -13px;
            left: 50%;
            background: url(../img/regions-block-arrow.png);
            width: 19px;
            height: 13px;
        }
    }
    &__item {

    }
    &__link {
        font-size: 13px;
        font-family: $fontopensans;
        color: #929292;
        text-decoration: none;
        vertical-align: middle;

        &:hover {
            color: lighten(#929292, 10%);
            text-decoration: underline;
        }
    }

    &__items-list {
        box-sizing: border-box;
        width: 150px;
        margin: 5px 10px;
    }

    &__list-letter {
        font-size: 16px;
        font-family: $fontopensans;
        color: #004d7b;
        text-transform: uppercase;
        line-height: 1.2;
        min-height: 19px;
    }
}