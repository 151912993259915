.viewallbtn {
    margin-bottom: 40px;

    &__link {
        color: #ffffff;
        font-weight: bold;
        font-family: $fontcuprum;
        font-size: 17px;
        text-decoration: none;
        text-align: center;
        flex-direction: row;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        min-height: 44px;
        padding: 8px 10px;
        text-align: center;
        background: #004d7b;

        &:hover {
            background: #5597d1;
        }
    }
}
