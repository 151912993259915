.entitybanner {
    margin-bottom: 20px;

    &__item {
        margin-bottom: 20px;
        background: #fff;
        border: 1px solid #ebebeb;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        border-radius: 10px 0 0 0 / 10px 0 0 0;
        height: calc(100% - 20px);

        &:hover {
            border-color: #d2dee9;
        }
        &:hover .entitybanner__address {
            background: #d2dee9;
        }
    }
    &__title {
        padding: 5px;
        font-size: 16px;
        color: #004d7b;
        font-family: $fontcuprum;
        text-align: center;
        padding-bottom: 0;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        min-height: 60px;
    }
    &__description {
        padding: 5px;
        text-align: center;
        font-family: $fontcuprum;
        font-size: 14px;
        color: #929292;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        min-height: 60px;
    }
    &__imgcase {
        display: block;
        margin: auto;
        margin-bottom: 5px;
        position: relative;
        min-width: 200px;
    }
    &__img {
        width: 100%;
        max-width: 200px;
        height: 100%;
        max-height: 130px;
    }
    &__address {
        background: #ebebeb;
        padding: 5px;
        min-height: 50px;
        color: #929292;
        font-size: 13px;
        text-align: center;
        line-height: 15px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-family: $fontcuprum;
    }

    &__badge {
        left: -14px;
        bottom: 7px;
    }

    &__item:hover {
        border-color: #d2dee9;
    }
    &__item:hover &__address {
        background: #d2dee9;
    }
}
