.header {
    background: #3b434d;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    z-index: 1000;

    &--index {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    &__item {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;

        &--logo {
            flex: 0 0 auto;
        }
        &--slogan {
            flex: 1 0 auto;
        }
        &--special {
            flex: 0 0 auto;
        }
        &--floatmenu {
            flex: 2 0 auto;
        }
    }
}
