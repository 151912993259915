.content {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 40px;

    &.scrolling {
        margin-top: 81px;
    }
    &.inner {
        margin-top: 45px;
    }
}
