

//
@import "_reset";
@import "_fonts";
@import "_base";
@import "_wrapper";
@import "_color";
@import "_include";
@import "_mixin";
@import "_grid";
@import "tags";
//
@import "elements/input";
@import "elements/btn";
@import "elements/banner";
@import "elements/calendinfo";
@import "elements/timeseparator";
@import "elements/holidays";
//
@import "blocks/logo";
@import "blocks/entity-badge";
@import "blocks/organization-list";
@import "blocks/adjacent-specialization";
@import "blocks/map";
//
@import "header/header";
@import "header/slogan";
@import "header/region";
@import "header/region-selection-box";
@import "header/floatmenu";
@import "header/special";
//
@import "content/content";
@import "content/breadcrumbs";
//
@import "content/leftbar/leftbar";
@import "content/leftbar/search";
@import "content/leftbar/organization";
@import "content/leftbar/specialization";
@import "content/leftbar/filterservice";
@import "content/leftbar/popularservice";
@import "content/leftbar/entitybanner";
@import "content/leftbar/personals";
@import "content/leftbar/selectedfilter";
@import "content/leftbar/viewallbtn";
//
@import "content/centerbar/centerbar";
@import "content/centerbar/mainslider";
@import "content/centerbar/category";
@import "content/centerbar/shortnews";
@import "content/centerbar/full-news";
@import "content/centerbar/maintitle";
@import "content/centerbar/pagetitle";
@import "content/centerbar/datefilter";
@import "content/centerbar/newsfilter";
@import "content/centerbar/searchfilter";
@import "content/centerbar/trailernews";
@import "content/centerbar/videorow";
@import "content/centerbar/pagination";
@import "content/centerbar/usefulinfo";
@import "content/centerbar/selectedline";
@import "content/centerbar/specials";
@import "content/centerbar/comments";
@import "content/centerbar/organization";
@import "content/centerbar/doctors";
@import "content/centerbar/specialization-selection";
@import "content/centerbar/specialization-filter";
@import "blocks/opinion";
@import "content/centerbar/uslugi";
//
@import "content/centerbar/errorblock";
//
@import "content/rightbar/rightbar";
//
@import "footer/footer";
@import "footer/copyright";
@import "footer/social";
@import "footer/aferta";
@import "footer/mainlinks";
//
@import "scripts/lightslider.scss";
@import "scripts/lightgallery.scss";
@import "scripts/daterangepicker.scss";
@import "scripts/formstyler.scss";
@import "scripts/radiobuttontoggle.scss";
//
@import "content/rightbar/calend";
//
@import "header/loading";
@import "header/scrolling";
@import "header/blind";
@import "media.scss";
