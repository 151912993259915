.category {

    &__list {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        @media(max-width: 1190px){
            flex-wrap: wrap;
        }
    }
    li,
    &__item {
        padding-right: 5px;
    }
    a,
    &__link {
        line-height: 45px;
        display: block;
        font-family: $fontcuprum;
        font-weight: normal;
        // padding: 0 10px;
        margin-right: 15px;
        font-size: 17px;
        color: #3b434d;
        text-decoration: none;

        &:hover {
            color: #e65e26;
        }
    }
}
