.wrapper {
    width: 100%;
    max-width: 1220px;
    margin: auto;
    position: relative;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;

    &--header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }
    &--content {
        flex-direction: row;

        @media screen and (max-width: 1220px) {
            padding: 0 15px;
            box-sizing: border-box;
            flex-direction: column;
        }
    }
    &--footer {
        flex-direction: row;
        justify-content: space-between;
    }
}
