.btn {
    background: #5597d1;
    width: 100%;
    display: block;
    cursor: pointer;
    font-family: $fontcuprum;
    font-size: 20px;
    color: #ffffff;
    height: 40px;
    line-height: 40px;

    &:hover {
        background: #004d7b;
    }
}
