.shortnews {
    background-color: #ebebeb;
    min-height: 320px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    border-radius: 10px 0 0 0 / 10px 0 0 0;
    margin-bottom: 10px;

    &:hover {
        background-color: #ececec;
    }
    &__link {
        text-decoration: none;
    }
    &__imgcase {
        position: relative;
    }
    &__textpos {
        min-height: 100px;
        background: -moz-linear-gradient(top, rgba(59, 67, 77, 0) 0%, rgba(59, 67, 77, 0) 10%, rgba(59, 67, 77, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(59, 67, 77, 0) 0%, rgba(59, 67, 77, 0) 10%, rgba(59, 67, 77, 1) 100%);
        background: linear-gradient(to bottom, rgba(59, 67, 77, 0) 0%, rgba(59, 67, 77, 0) 10%, rgba(59, 67, 77, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#003b434d', endColorstr='#3b434d', GradientType=0);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        align-content: flex-end;
    }
    &:hover &__textpos {
        background: none;
    }
    &__title {
        font-family: $fontcuprum;
        color: #ffffff;
        font-size: 17px;
        padding: 8px 10px;
        width: 100%;
        box-sizing: border-box;
    }
    &:hover &__title {
        background: rgba(255, 255, 255, .5);
        color: #3b434d;
    }
    &__img {
        border-radius: 10px 0 0 0 / 10px 0 0 0;
        display: block;
        max-width: 100%;
    }
    &__description {
        border-bottom: 1px solid #d4d4d4;
        height: 137px;
        box-sizing: border-box;
        padding: 6px 10px 10px;
        color: #1a1916;
        font-size: 15px;
        line-height: 17px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: $fontcuprum;
    }
    &__details {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 30px;
    }
    &__category {
        line-height: 30px;
        text-align: left;
        padding: 0 10px;
        color: #eb914e;
        text-decoration: none;
        font-size: 13px;
        font-family: $fontopensans;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        height: 30px;
        white-space: nowrap;
        word-wrap: normal;
        background: url("../img/icon_category@2x.png") 0 0 / 30px 60px no-repeat;
        padding-left: 23px;

        &:hover {
            color: #e65e26;
            background: url("../img/icon_category@2x.png") 0 -30px / 30px 60px no-repeat;
        }
    }
    &__date {
        padding-right: 10px;
        color: #929292;
        font-size: 13px;
    }

    &--big {
        @media screen and (max-width: 1000px) {
            max-width: 470px;
        }
    }
    &--big &__title {
        font-size: 25px;
    }
}
