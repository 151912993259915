.datefilter {
    white-space: nowrap;
    word-wrap: normal;
    line-height: 30px;
    font-size: 12px;
    color: #929292;
    display: block;
    width: auto;
    padding-right: 5px;
    height: 30px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &__icon {
        width: 30px;
        height: 30px;
        background: url('../img/icon_news_titl@2x.png') 0 0 / 30px 90px no-repeat #e65e26;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
            background-color: #eb914e;
        }
    }
    &__text {
        padding-left: 5px;
    }
}
