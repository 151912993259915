.mainslider {
    background-color: #ebebeb;
    font-family: $fontcuprum;
    border-radius: 10px 0 0 0 / 10px 0 0 0;
    position: relative;
    z-index: 90;

    &:hover &__textpos {
        background: none;
    }
    &:hover &__title {
        background: rgba(255, 255, 255, .5);
        color: #3b434d;
    }
    &__item {
        position: relative;
        min-height: 320px;
    }
    &__img {
        border-radius: 10px 0 0 0 / 10px 0 0 0;
    }
    &__textpos {
        min-height: 100px;
        background: -moz-linear-gradient(top, rgba(59, 67, 77, 0) 0%, rgba(59, 67, 77, 0) 10%, rgba(59, 67, 77, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(59, 67, 77, 0) 0%, rgba(59, 67, 77, 0) 10%, rgba(59, 67, 77, 1) 100%);
        background: linear-gradient(to bottom, rgba(59, 67, 77, 0) 0%, rgba(59, 67, 77, 0) 10%, rgba(59, 67, 77, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#003b434d', endColorstr='#3b434d', GradientType=0);
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        align-content: flex-end;
    }
    &__title {
        font-size: 25px;
        color: #ffffff;
        padding: 6px 10px;
    }
    &__category {
        position: absolute;
        z-index: 100;
        bottom: 0;
        line-height: 30px;
        text-align: left;
        padding: 0 10px;
        color: #eb914e;
        text-decoration: none;
        font-size: 13px;
        font-family: $fontopensans;

        &:hover {
            color: #e65e26;
        }
        &::before {
            content: "⚫";
            color: #eb914e;
            padding-right: 3px;
        }
        &:hover::before {
            color: #e65e26;
        }
    }
}
