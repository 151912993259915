.input {
    width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
    background: #fff;
    text-align: center;
    border-radius: 15px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #d7d7d7;
    font-family: $fontopensans;
    font-size: 11px;
}
