.floatmenu {
    display: none;
    flex-direction: row;
    justify-content: flex-start;

    &__list {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
    }
    &__item {
        margin-right: 25px;
        filter: grayscale(100) brightness(100) contrast(100);

        &:last-child {
            margin-right: 5px;
        }
        &:nth-child(1) {
            background: url('../img/floatmenu_01@2x.png') 0 50% / 30px auto no-repeat;
        }
        &:nth-child(2) {
            background: url('../img/floatmenu_02@2x.png') 0 50% / 30px auto no-repeat;
        }
        &:nth-child(3) {
            background: url('../img/floatmenu_03@2x.png') 0 50% / 30px auto no-repeat;
        }
        &:nth-child(4) {
            background: url('../img/floatmenu_04@2x.png') 0 50% / 30px auto no-repeat;
        }
        &:nth-child(5) {
            background: url('../img/floatmenu_05@2x.png') 0 50% / 30px auto no-repeat;
        }

        &:hover {
            filter: none;
        }

        &--active {
            filter: none;
        }
    }
    &__link {
        color: #eb914e;
        font-size: 15px;
        font-family: $fontcuprum;
        padding-left: 30px;
        padding-right: 5px;
        display: block;
        text-decoration: none;
        line-height: 50px;
    }

}
