$organizationInfoSelector: 'organization-info';
$organizationMediaSelector: 'organization-media';

.#{$organizationInfoSelector} {

    &__container {
        box-sizing: border-box;
        border: 1px solid #ebebeb;
        border-radius: 10px 0 0 0;
        padding: 0 2px 0 9px;
        margin-bottom: 10px;
    }

    &__header {
        margin-bottom: 30px;
    }

    &__title {
        font-family: $fontcuprum;
        font-size: 32px;
        font-weight: bold;
        color: #004d7b;
        padding: 3px 0 0 0;
        margin-bottom: 5px;
    }

    &__vrach-img {
        position: relative;
    }

    &__staz {
        position: absolute;
        background: #ebebeb;
        padding: 5px 10px;
        border-radius: 0 10px 0 10px;
        bottom: 0;
        right: 18px;
        width: 32px;
        height: 55px;
        text-align: center;
        font-size: 13px;
        color: #929292;
    }

    &__staz span {
        font-size: 18px;
        color: #004d7b;
    }

    &__sub-title {
        font-family: $fontcuprum;
        font-size: 18px;
        font-weight: bold;
        color: #3b434d;
    }

    &__logo {
        margin-bottom: 3px;
        max-width: 100%;
        height: auto;
    }

    &__address {
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #929292;
        margin-bottom: 13px;
    }

    &__map-link {
        background: #ebebeb;
        padding: 6px 10px;
        text-align: center;
        font-size: 13px;
        margin-bottom: 10px;
        color: #929292;
        display: block;

        &:hover {
            background: #d2dee9;
        }
    }

    &__map-container {
        width: 700px;
        max-width: 100%;
        position: relative;
        padding-top: 100%;
    }

    &__map {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__contacts {

        &-header {
            font-family: $fontcuprum;
            font-size: 13px;
            color: #929292;
            margin-bottom: 2px;
            display: block;
        }

        &-phone {
            font-family: $fontcuprum;
            padding: 2px 0 1px 25px;
            background: url(../img/icon_contact_01.png) no-repeat left center;
            margin-bottom: 4px;
            display: block;
        }

        &-phones-list {
            max-height: 123px;
            transition: all 0.5s ease-in-out;
            overflow: hidden;
            &--all {
                max-height: 9999px;
            }
        }

        &-skype {
            font-family: $fontcuprum;
            padding: 2px 0 1px 25px;
            background: url(../img/skype.png) no-repeat left center;
            margin-bottom: 4px;
            display: block;
        }

        &-show-phones {
            font-family: $fontcuprum;
            font-size: 13px;
            padding-left: 4px;
            margin-bottom: 5px;
            text-decoration: none;
            color: #929292;

            &:after {
                font-family: $fontcuprum;
                content: '>';
                display: inline-block;
                width: 7px;
                height: 7px;
                margin-left: 5px;
            }
        }

        &-email {
            font-family: $fontcuprum;
            padding: 2px 0 1px 25px;
            background: url(../img/icon_contact_03.png) no-repeat left center;
            margin-bottom: 5px;
            color: #004d7b;
            text-decoration: none;
            display: block;
        }

        &-site {
            font-family: $fontcuprum;
            padding: 2px 0 1px 25px;
            background: url(../img/icon_contact_02.png) no-repeat left center;
            color: #004d7b;
            text-decoration: none;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &-workmode {
            font-family: $fontcuprum;
            line-height: 22px;
            margin-bottom: 32px;
            display: block;
        }
    }

    &__description {
        font-family: $fontopensans;
        font-size: 15px;
        line-height: 18px;
        position: relative;
        overflow: hidden;
        max-height: none;

        p {
            margin: 10px 0;
        }

        &--short {
            max-height: 100px;
            &:after {
                background: rgba(0, 0, 0, 0) linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%) repeat scroll 0 0;
                bottom: 0;
                content: "";
                display: block;
                height: 100px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }
    }

    &__description-read-more {
        text-align: center;
        font-family: $fontopensans;
        font-size: 13px;
        color: #004d7b;
        padding-top: 3px;
        margin-bottom: 29px;
        cursor: pointer;
    }

    &__menu-vrachi {
        box-sizing: border-box;
        vertical-align: top;
        font-size: 15px;
        margin-left: 10px;
        margin-top: 0px;
        padding-left: 0px;
        min-height: 165px;
        max-width: 230px;
        font-family: $fontcuprum;

        &-header {
            font-family: $fontcuprum;
            font-size: 13px;
            color: #929292;
            margin-bottom: 2px;
            display: block;
            margin-left: 5px;
        }
        &-item {

        }

        &-link {
            display: block;
            padding: 7px 6px;
            line-height: 14px;
            color: #004d7b;
            text-decoration: none;

            #{$organizationInfoSelector}--active &,
            &:hover {
                background: #ebebeb;
            }
            #{$organizationInfoSelector}--active & {
                color: #929292;
            }

        }
    }
    &__menu {
        box-sizing: border-box;
        vertical-align: top;
        font-size: 15px;
        margin-left: 14px;
        margin-top: -6px;
        padding-left: 3px;
        border-left: 1px solid #ebebeb;
        max-width: 230px;
        font-family: $fontcuprum;

        &-item {

        }

        &-link {
            display: block;
            padding: 7px 6px;
            line-height: 14px;
            color: #004d7b;
            text-decoration: none;

            #{$organizationInfoSelector}--active &,
            &:hover {
                background: #ebebeb;
            }
            #{$organizationInfoSelector}--active & {
                color: #929292;
            }

        }
    }

    &__show-services {
        background: #ebebeb;
        padding: 6px 10px;
        text-align: center;
        font-size: 13px;
        margin-bottom: 10px;
        color: #929292;
        display: block;
        font-family: $fontcuprum;
        text-decoration: none;
    }

    &__documents {
        background: #ebebeb;
        min-width: 100%;

        td {
            border: none;
            padding: 5px;
            vertical-align: top;
            font-family: $fontopensans;
            font-size: 13px;
            line-height: 15px;
            color: #3b434d;
            @media screen and (max-width: 768px) {
                display: block;
            }
        }
        tr td:first-child {
            padding-right: 19px;
        }
        tr:first-child td {
            padding-top: 10px;
        }
        tr:last-child td {
            padding-bottom: 7px;
        }
        a {
            display: block;
            color: #004d7b;
            text-decoration: none;
            position: relative;
            padding-left: 20px;
        }
        .table_icon:before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 15px;
            margin: 0 5px 0 0;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../img/icon_table_link.png);
        }
    }

    &__socials {
        filter: grayscale(1);

        & > li {
            display: inline-block;
        }
    }

    &__slidedown {
        margin-bottom: 14px;

        &-title {
            font-family: $fontcuprum;
            padding: 9px 0;
            position: relative;
            font-size: 20px;
            font-weight: 600;
            color: #3b434d;
        }

        &-subtitle {
            color: #004d7b;
            font-size: 13px;
            display: block;
            max-width: 95%;
        }

        &-mesto {
            color: #929292;
            font-size: 13px;
        }

        &-content {

        }

        &-handler {
            position: absolute;
            top: 50%;
            right: 3px;
            width: 15px;
            height: 9px;
            margin-top: -4px;
            cursor: pointer;
            background: url(../img/blockslide_sprite.png) no-repeat;
        }
    }

    &__administration-content {
        padding-left: 10px;
    }

    &__sub-organizations {
        &-header {
            font-family: $fontcuprum;
            font-size: 20px;
            font-weight: bold;
            color: #3b434d;
            margin-bottom: 10px;
        }
    }
}

.#{$organizationMediaSelector} {
    &__container {
        font-family: $fontcuprum;
        border: 1px solid #ebebeb;
        padding: 8px 9px 0 9px;
    }

    &__header {
        color: #3b434d;
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 17px;
    }

    &__list {
        font-family: $fontopensans;
        font-size: 15px;
        margin-bottom: 5px;
        display: block;

        &-item {
            margin-bottom: 3px;
            display: block;
            text-decoration: none;
            color: #000000;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__slider {
        margin: 0 30px 13px !important;
        width: calc(100% - 60px);

        &-slide {
            text-align: center;

            &-content {
                max-width: 200px;
                margin: 0 auto;
            }
        }

        .slick-arrow {

            width: 25px;
            height: 45px;
            position: absolute;
            top: 50%;
            margin-top: -23px;
            cursor: pointer;
            background-image: url(../img/slider/slidersprite.png);

            &.slick-prev {
                background-position: left top;
                left: -27px;

                &:hover {
                    background-position: left bottom;
                }
            }
            &.slick-next {
                background-position: right top;
                right: -27px;

                &:hover {
                    background-position: right bottom;
                }
            }
        }
    }
}