.navigation__mobileButton {
    display: none;
}

@media (max-width: 1300px) {
    .search-result-2 {
        .personals__title .personals__name {
            font-size: 14px;
        }
        .personals__description, .personals__category {
            font-size: 12px;
        }
    }
}

@media (max-width: 1220px) {
    .uslugi {
        .specsingle--leftlinks--item {
            width: 100%;
        }
        .search-result-2 {
            width: 100%;
            display: block;
        }
        .grid-dis-row {
            max-width: 100%;
        }
        .grid-col-2 {
            width: 50%;
        }
    }
}

@media (min-width: 960px) and (max-width: 1220px) {

    .wrapper--content {
        display: block;
    }
    .leftbar {
        width: 100%;
        max-width: 230px;
        vertical-align: top;
        display: inline-block;
        margin-right: 20px;
    }
    .centerbar {
        width: calc(100% - 260px);
        display: inline-block;
        vertical-align: top;
    }
}

@media screen and (max-width: 960px) {
    .navigation__mobileButton {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 4%;
    }
    .navigation__mobileButton img {
        height: 32px;
    }
    .header.scrolling .floatmenu {
        display: none;
        position: absolute;
        right: 0;
        top: 70px;
        background: #3b434d;
    }
    .header.inner .floatmenu {
        display: none;
        position: absolute;
        right: 0;
        top: 50px;
        background: #3b434d;
    }
    .header .floatmenu .floatmenu__list {
        display: block;
        padding: 10px;
    }
    .header .floatmenu .floatmenu__list .floatmenu__link {
        line-height: 30px;
    }
    .region__selection-box {
        width: auto;
        right: 0;
        top: 50px;
    }
    .region-selection__box:before {
        left: 78%;
    }
}

@media (min-width: 641px) and (max-width: 960px) {
    .wrapper--content {
        display: block;
    }
    .leftbar {
        width: 100%;
        max-width: 230px;
        display: inline-block;
        margin-right: 20px;
    }
    .centerbar {
        width: calc(100% - 260px);
        display: inline-block;
        vertical-align: top;
    }
    .grid-dis-col-33:last-child, .grid-dis-col-66:last-child, .grid-dis-col-1:last-child {
        width: 100%;
        margin-top: 15px !important;
        max-width: 100%;
    }
    .doctors__photo {
        margin: 0 auto;
    }
    .organization-info__vrach-img {
        max-width: 270px;
        margin: 0 auto;
    }
    .wrapper--footer {
        display: block !important;
    }
    .footer__logo {
        display: inline-block;
        margin-left: 10px;
    }
    .copyright {
        display: inline-block;
        width: calc(100% - 140px);
        margin-right: 0;
    }
    .copyright span {
        display: block;
    }
    .aferta {
        display: block;
        width: calc(100% - 20px);
        margin: 0 10px;
    }
    .footer__right {
        display: block;
        width: calc(100% - 20px);
        margin: 0 10px;
    }
    .footer__right .mainlinks {
        display: block;
    }
    .footer__right .mainlinks li {
        display: inline-block;
    }
    .category__list {
        display: block;
    }
    .category li, .category__item {
        width: 47%;
        display: inline-block;
        text-align: center;
    }
    .slogan__text {
        font-size: 14px;
    }
    .region__name {
        display: none;
    }
}

@media screen and (max-width: 641px) {
    .uslugi .leftbar {
        display: block !important;
        width: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .specialization-selection__container {
        column-count: 2;
    }
    .category li, .category__item {
        width: 47%;
        display: inline-block;
        text-align: center;
    }
    .category a, .category__link {
        line-height: 30px;
        display: block;
        margin-right: 0px;
        font-size: 15px;
    }
    .grid-dis-row {
        max-width: 100%;
        margin: 0 auto;
        width: 100%;
    }
    .grid-dis-col-1 {
        width: 100%;
        max-width: 320px;
    }
    .calendinfo {
        text-align: center;
    }
    .maintitle {
        display: block;
        height: auto;
        padding: 10px 0;
        border-radius: 10px;
    }
    .trailernews {
        display: block;
    }
    .trailernews__textcase {
        display: block;
        width: 100%;
    }
    .trailernews__details {
        display: block;
        height: auto;
        width: 100%;
    }
    .trailernews__left, .trailernews__right {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
    }
    .trailernews__views {
        width: 10%;
        display: inline-block;
    }
    .trailernews__comments {
        width: 40%;
        display: inline-block;
    }
    .trailernews__imgc {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        max-width: none;
        height: auto;
        max-height: none;
    }
    .trailernews__imgcase {
        margin: 0 auto;
    }
    .videorow {
        display: block;
    }
    .videorow__item {
        width: 100%;
        display: block;
        margin: 10px auto;
    }
    .region__name {
        display: none;
    }
    .slogan__text {
        display: none;
    }
    .category li, .category__item {
        width: 47%;
        display: inline-block;

    }
    .category__list {
        display: block;
    }
    .centerbar {
        width: 100%;
    }
    .special-items__item {
        width: 320px;
        margin: 15px auto;
    }
    .logo--header {
        width: 185px;
    }
    .logo--header .logo__img {
        width: 175px;
    }
    .region__section-box {
        width: 300px;
    }
    .region__section__items-list {
        display: block;
        width: calc(100% - 20px);
    }
    .search-float {
        display: none !important;
    }
    .leftbar {
        display: none !important;
    }
    .doctors__photo {
        margin: 0 auto;
    }
    .organization-info__vrach-img {
        max-width: 270px;
        margin: 0 auto;
    }
    .organization-info__container .vrach-info {
        margin: 15px 0;
        min-height: auto;
    }
    .grid-dis-col-33:last-child, .grid-dis-col-66:last-child, .grid-dis-col-1:last-child {
        width: 100%;
        margin-top: 15px !important;
    }
    .vrachi-border {
        border-left: none;
    }
    .vrach-title-dolznosti {
        border-bottom: none;
    }
    .organization-media {
        display: none !important;
    }
    .wrapper--footer {
        display: block !important;
    }
    .footer__logo {
        display: inline-block;
        margin-left: 10px;
    }
    .copyright {
        display: inline-block;
        width: calc(100% - 140px);
        margin-right: 0;
    }
    .copyright span {
        display: block;
    }
    .aferta {
        display: block;
        width: calc(100% - 20px);
        margin: 0 10px;
    }
    .footer__right {
        display: block;
        width: calc(100% - 20px);
        margin: 0 10px;
    }
    .footer__right .mainlinks {
        display: block;
    }
    .footer__right .mainlinks li {
        display: inline-block;
    }
    .grid-disrb-col-1, .grid-disrb-col-1:nth-child(3n) {
        width: 100%;
        max-width: 320px;
        margin: 10px auto;
    }
    .shortnews__description {
        height: auto;
    }
}

@media screen and (max-width: 510px) {
    .postbody {

        &__imgleft {
            margin: 10px auto;
            display: block;
        }
        &__imgright {
            margin: 10px auto;
            display: block;
        }
    }
}

@media screen and (max-width: 420px) {
    .specialization-selection__container {
        column-count: 1;
    }
    .uslugi {
        .search-result {
            margin-bottom: 30px;
            -moz-column-count: 1; /* Firefox */
            -webkit-column-count: 1; /* Safari and Chrome */
            column-count: 1;
        }
        .grid-col-2 {
            width: 100%;
        }
    }
}
