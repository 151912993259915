.organization {
    display: block;

    &__title {
        position: relative;
        a{
            text-decoration: none;
        }
        &::before {
            content: '';
            position: absolute;
            width: 51px;
            height: 51px;
            border-radius: 50%;
            left: -10px;
            top: -8px;
            border: 5px solid #ffffff;
            background: url("../img/icon_leftbar_innermenu_01@2x.png") 50% 50% / 23px 33px no-repeat #004d7b;
        }
    }
    &__list {
        width: 100%;
        margin-top: 5px;
        position: relative;
        z-index: 5;
        height: 0;
        overflow: hidden;

        &.active {
            height: auto;
            overflow: inherit;
        }
    }
    &__item {
        border-bottom: 1px solid #ebebeb;
    }
    &__link {
        color: #004d7b;
        text-decoration: none;
        font-size: 15px;
        padding: 8px;
        line-height: 19px;
        display: block;
        font-family: $fontcuprum;
        font-weight: normal;

        &:hover {
            background: #d2dee9;
        }
    }
}
