.selectedline {
    font-family: $fontcuprum;
    font-weight: bold;
    font-size: 20px;
    color: #929292;
    margin-bottom: 30px;
    display: block;

    &__item {
        background: url('../img/icon_filter_26x26@2x.png') 4px 50% / 13px 13px no-repeat;
        padding-left: 23px;
    }
}
