.centerbar {
    width: 78.2%;
    z-index: 2;

    &--with-map {
        margin-top: 300px;
        position: relative;
    }

}
