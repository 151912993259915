@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans_regular.eot');
    src: url('../fonts/opensans_regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/opensans_regular.woff2') format('woff2'),
    url('../fonts/opensans_regular.woff') format('woff'),
    url('../fonts/opensans_regular.ttf') format('truetype'),
    url('../fonts/opensans_regular.svg#opensans') format('svg');
    font-weight: 500;
    font-style: normal;
}

$fontopensans: 'opensans', Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;

@font-face {
    font-family: 'cuprum';
    src: url('../fonts/cuprum_regular.eot');
    src: url('../fonts/cuprum_regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/cuprum_regular.woff2') format('woff2'),
    url('../fonts/cuprum_regular.woff') format('woff'),
    url('../fonts/cuprum_regular.ttf') format('truetype'),
    url('../fonts/cuprum_regular.svg#cuprum') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'cuprum';
    src: url('../fonts/cuprum_bold.eot');
    src: url('../fonts/cuprum_bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/cuprum_bold.woff2') format('woff2'),
    url('../fonts/cuprum_bold.woff') format('woff'),
    url('../fonts/cuprum_bold.ttf') format('truetype'),
    url('../fonts/cuprum_bold.svg#cuprum') format('svg');
    font-weight: 700;
    font-style: normal;
}

$fontcuprum: 'cuprum', Impact, "Charcoal CY", sans-serif;

// Common weight name mapping
// 100 - Thin (Hairline)
// 200 - Extra Light (Ultra Light)
// 300 - Light
// 400 - Normal
// 500 - Medium
// 600 - Semi Bold (Demi Bold)
// 700 - Bold
// 800 - Extra Bold (Ultra Bold)
// 900 - Black (Heavy)
