.newsfilter {
    @extend .datefilter;

    &__icon {
        @extend .datefilter__icon;
        background: url('../img/icon_news_titl@2x.png') 0 -30px / 30px 90px no-repeat #e65e26;
    }

    &__open {
        position: absolute;
        width: 100%;
        top: 50px;
        left: 0;
        background: #ebebeb;
        cursor: default;
        box-sizing: border-box;
        padding: 0 10px;
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all .5s ease;

        &--show {
            opacity: 1;
            height: auto;
            transition: all .5s ease;
            padding: 10px;
            overflow: visible;
            border: 1px solid #c8c8c8;
        }
    }
    &__form {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    &__text {
    }
    &__selectcase {
        position: relative;
        text-overflow: ellipsis;

        select,
        option {
            display: none;
        }
    }
    &__selectwrap {
        position: relative;
    }
    &__select {
        /*width: 150px;
        background: #FFFFFF;
        border-radius: 15px;
        line-height: 30px;
        height: 30px;*/
        display: none;
    }
    &__submitcase {
    }
    &__submit {
        color: #eb914e;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: #e7632d;
        }
    }

    &__specializations,
    &__regions {
        cursor: pointer;
        padding-right: 20px;
    }

    &__specializations {
        background: url(../img/filter_ico_spec.png) right center no-repeat;
    }

    &__regions {
        background: url(../img/filter_ico_region.png) right center no-repeat;
        position: relative;
    }
}

.specializations {
    &__listBlock {
        background: #fff;
        box-shadow: 0 0 10px 0 #a8a8a8;
        top: 35px;
        box-sizing: border-box;
        padding: 0 10px 14px;
    }

    &__listRow {
        display: inline-block;
        vertical-align: top;
        width: calc(21% - 0.25em - 49px);
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }
    &__listRegionLink {
        font-size: 11px;
        font-family: "open_sansregular";
        color: #646464;
        text-transform: uppercase;
        line-height: 1.818;
        text-decoration: none;
        display: block;
    }
    &__listLetter {
        font-size: 16px;
        font-family: "open_sansregular";
        color: #646464;
        text-transform: uppercase;
        line-height: 1.2;
        margin-top: 10px;
        min-height: 19px;

        label {
            display: block;
        }
    }
    &__listLetter--spec {
        font-size: 15px;
        background: #ebebeb;
        color: #646464;
        padding: 3px 0;
    }

    &__listHeader {
        line-height: 46px;
        background-color: #ebebeb;
        margin: 0 -10px;
        padding-left: 10px;
        font-family: "open_sansregular";
        color: #646464;
        position: relative;

        label {
            font-size: 16px;
        }

        input[type=checkbox] {
            -webkit-appearance: checkbox;
            appearance: checkbox;
            margin: 3px 3px 3px 4px;
        }

        &:before,
        &:after {
            clear: both;
            content: '';
            display: block;
        }

        &Button {
            background: #e65e26;
            font-size: 11px;
            line-height: 1;
            padding: 9px;
            position: absolute;
            right: 10px;
            top: 9px;
            color: #fff;
            border-radius: 15px;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
    &__listLine {
        margin-top: 10px;
    }
    &__listList {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    &__listRow--spec {
        width: calc(37% - 0.25em - 49px);
        &:nth-child(3n) {
            margin-right: 0;
        }
        .specializations__listList {
            max-height: 125px;
            overflow: hidden;
        }
    }

    &__listRegionMore {
        text-decoration: none;
        color: #00adee;
        text-transform: uppercase;
        margin-left: 10px;
        cursor: pointer;
        &:after {
            content: ' >';
        }
    }
}

.regionsfilter {
    &__container {
        position: absolute;
        top: 100%;
        left: -20px;
        padding: 5px;
        background: #ebebeb;
        z-index: 2;
        cursor: default;
    }
    &__label {
        white-space: nowrap;
    }
    &__checkbox {
        -webkit-appearance: checkbox;
        appearance: checkbox;
        margin-right: 4px;
    }
}

.fancycheckbox {
    &__clearfix {
        &:before,
        &:after {
            display: block;
            content: '';
            clear: both;
        }
    }
    &__checkbox,
    &__selectGroup {
        -webkit-appearance: checkbox;
        appearance: checkbox;
        display: block;
        float: left;
        margin: 3px 3px 3px 4px;
    }
    &__label {
        @extend .fancycheckbox__clearfix;
    }
    &__labelText {
        display: block;
        float: left;
        width: 84%;
        margin-left: 3px;
        margin-top: 2px;
        font-size: 11px;
    }
    &__labelHeaderText {
        font-size: 15px;
    }
    &__selectGroupHeader {
        @extend .fancycheckbox__clearfix;
    }
}