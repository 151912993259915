%h2 {
    font-family: $fontcuprum;
    font-size: 25px;
    margin: 20px 0;
    font-weight: 600;
}

%h3 {
    font-family: $fontcuprum;
    font-size: 20px;
    margin: 20px 0;
    font-weight: 600;
}

%p {
    font-family: $fontopensans;
    font-size: 15px;
    margin: 20px 0;

    img {
        float: left;
        margin-right: 20px;
        margin-bottom: 23px;
        margin-top: 6px;
    }
}

%ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        padding-left: 12px;
        position: relative;
        font-size: 16px;
        font-family: $fontopensans;
        color: #404040;
        line-height: 1.5;
        margin-bottom: 10px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 10px;
            background: #265e86;
            border-radius: 50%;
            width: 6px;
            height: 6px;
        }
    }
}

%ol {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        counter-increment: step-counter;
        font-size: 16px;
        font-family: $fontopensans;
        color: #404040;
        line-height: 1.5;

        &:before {
            content: counter(step-counter) ".";
            font-size: 11px;
            font-family: $fontopensans;
            color: #004d7b;
            font-weight: bold;
        }
    }
}

%table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #e6e6e6;
    margin-top: 20px;

    th {
        background: #e6e6e6;
        font-size: 11px;
        font-family: $fontopensans;
        color: #004d7b;
        text-transform: uppercase;
        line-height: 1.2;
        padding: 17px 0 17px 12px;
        border: 1px solid #e6e6e6;
        text-align: left;

        &:nth-child(1) {
            width: 19%;
        }

        &:nth-child(2) {
            width: 23%;
        }
    }

    td {
        font-size: 14px;
        font-family: $fontopensans;
        color: #404040;
        line-height: 1.714;
        padding: 14px 0 14px 12px;
        border: 1px solid #e6e6e6;
        vertical-align: top;

        &:nth-child(1) {
            width: 13%;
        }

        &:nth-child(2) {
            width: 23%;
        }
    }
}

%blockquote {
    padding: 5px 25px 5px 60px;
    background: #ebebeb url('../img/cite.png') 20px 30px no-repeat;

    p {
        font-size: 25px;
        font-family: $fontcuprum;

        small {
            font-size: 15px;
        }
    }
}