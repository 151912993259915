.full-news {

    &__details {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        font-size: 13px;
        width: 100%;
        justify-content: space-between;
        height: 14px;
        line-height: 14px;
        margin-bottom: 20px;
    }
    &__left {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        height: 14px;
        line-height: 14px;
    }
    &__date {
        margin-right: 20px;
        color: #929292;
    }
    &__category {
        color: #e65e26;
        text-decoration: none;
        margin-right: 20px;
        height: 14px;
        line-height: 14px;
        background: url("../img/icon_category@2x.png") 0 -7px / 30px 60px no-repeat;
        padding-left: 23px;

        &:hover {
            color: #eb914e;
            background: url("../img/icon_category@2x.png") 0 -37px / 30px 60px no-repeat;
        }
    }
    &__region {
        color: #e65e26;
        text-decoration: none;
        margin-right: 20px;
        background: url("../img/icon_regionmark.png") 0 0 / 10px 28px no-repeat;
        padding-left: 15px;
        height: 14px;
        line-height: 14px;
        position: relative;
        z-index: 1;

        &:hover {
            color: #eb914e;
            background-position: 0 -14px;
        }
    }
    &__right {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        color: #929292;
        height: 14px;
        line-height: 14px;
    }
    &__views {
        margin-right: 20px;
        background: url("../img/icon_views@2x.png") left center / auto 12px no-repeat;
        padding-left: 23px;
        height: 14px;
        line-height: 14px;
    }
    &__comments {
        background: url("../img/icon_comments@2x.png") left center / auto 15px no-repeat;
        padding-left: 18px;
        height: 14px;
        line-height: 14px;
    }
    &__slideritem {
        text-align: center;
    }
    &__sliderimg {
        display: block;
        width: 100%;
        max-width: 710px;
        height: 100%;
        max-height: 360px;
        border-radius: 10px 0 0 0 / 10px 0 0 0;
        margin: auto;
    }
    .lSPager.lSGallery {

        li {
            margin: 5px 0;

            img {
                margin: auto;
                filter: grayscale(0.75) saturate(75%) contrast(75%) brightness(0.75);

                &:hover {
                    filter: none;
                }
            }
            &.active img {
                filter: none;
            }
        }
    }

    &__block-title {
        font-family: "cuprum", Impact, "Charcoal CY", sans-serif;
        font-size: 20px;
        color: #004d7b;
        font-weight: 600;
        padding: 10px 0;
        display: block;
    }

    &__announce {
        font-size: 18px;
        font-family: $fontcuprum;
        margin: 20px 0;
    }

    &__text {
        margin-bottom: 40px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ebebeb;

        &:before,
        &:after {
            display: block;
            clear: both;
            content: '';
        }

        h2 {
            @extend %h2;
        }

        h3 {
            @extend %h3;
        }

        p {
            @extend %p;
        }

        ul {
            @extend %ul;
        }

        ol {
            @extend %ol;
        }

        table {
            @extend %table;

            .mobileth {
                display: none;
            }
        }

        blockquote {
            @extend %blockquote;
        }

        .col3__item {
            display: inline-block;
            width: calc(33% - 0.25em - 10px);
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }

        .col2__item {
            display: inline-block;
            width: calc(50% - 0.25em - 10px);
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__other-materials {

        margin: 20px 0;

        &-read-all {
            color: #fff;
            background-color: #eb914e;
            display: block;
            text-align: center;
            padding: 10px 0;
            text-decoration: none;
            font-size: 13px;

            &:hover {
                background-color: #d46818;
            }
        }
    }

    &__source {
        display: block;

        &-item {
            margin: 10px 0;
            display: block;
            font-size: 15px;
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            line-height: 1.4;

            a {
                font-size: inherit;
                color: #004d7b;
                font-family: inherit;
            }
        }

        &-title {
            font-size: 13px;
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            display: inline-block;
            color: #a2a1a1;
        }
    }


    &__gallery {
        position: relative;

        &-img {
            overflow: hidden;
            border-radius: 10px 0 0 0;
        }

        &-preview {
            width: 140px;
        }

        &-prevs {
            &-left,
            &-right {
                display: inline-block;
                background: url('../img/slider/sliderLeft.png');
                cursor: pointer;
                width: 25px;
                height: 45px;
                vertical-align: top;
                transform: translate(0, -50%);
                position: absolute;
                bottom: 15px;
                top: auto;
            }

            &-left {
                margin-right: 15px;
                &:hover {
                    background: url('../img/slider/sliderLeft-hover.png');
                }
            }

            &-right {
                background: url('../img/slider/sliderRight.png');
                right: 0;
                &:hover {
                    background: url('../img/slider/sliderRight-hover.png');
                }
            }
        }
    }

  &__video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

    .fotorama__nav-wrap {
        width: -moz-calc(100% - 80px);
        width: -webkit-calc(100% - 80px);
        width: calc(100% - 80px);
        margin-left: 40px !important;
        position: relative;
    }
}