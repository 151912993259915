.social {

    &--fixed {
        position: fixed;
        right: 10px;
        top: 25%;
        transform: translateY(-50%);

        @media screen and (max-width: 1300px) {
            display: none;
        }
    }
    &--static {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
    &__item {
        width: 25px;
        height: 25px;
        margin: 0 2px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 5px;
    }
    &__link {
        display: block;
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-size: 27px 27px;
        background-position: center center;

        &--fb {
            background-image: url("../img/icon_facebook.svg");
        }
        &--vk {
            background-image: url("../img/icon_vkontakte.svg");
        }
        &--ok {
            background-image: url("../img/icon_odnoklassniki.svg");
        }
        &--tw {
            background-image: url("../img/icon_twitter.svg");
        }
        &:hover {
            opacity: .75;
        }
    }
}
