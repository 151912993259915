.slogan {
    font-family: $fontcuprum;
    font-weight: 700;
    color: #ffffff;
    font-size: 25px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__text {
        padding-left: 35px;
    }
}
