.calendinfo {
    color: #929292;
    font-size: 13px;
    text-align: right;
    line-height: 20px;
    margin: 15px 0 0 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;

    &__item:first-child {
        padding-bottom: 15px;
    }

    &__calendcase {
        @media(max-width: 1100px) and (min-width: 1001px) {
            display: none;
        }
    }
    &__calendcase {
        @media (max-width: 1001px) {
            text-align: left;
        }
    }
}
