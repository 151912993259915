$specializationSelectionClass: 'specialization-selection';

.#{$specializationSelectionClass} {

    &__container {
        background: #ffffff;
        box-sizing: border-box;
        width: calc(100% - 26px);
        padding: 10px;
        display: block;
        //max-height: 800px;
        //display: flex;
        //flex-direction: column;
        //flex-wrap: wrap;
        //align-items: flex-start;
        column-count: 3;
        column-gap: 10px;

        &:before,
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    &__group {
        //width: calc(33.333% - 10px);
        margin: 5px 5px 5px 5px;
        display: block;
        //float: left;

        &:nth-child(3n) {
            /*width: 33.333%;
            margin-right: 0;*/
        }
    }

    &__list {
        max-height: 125px;
        overflow: hidden;
        display: block;

        &-item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 5px;

            label {
                display: block;
                font-size: 13px;
                font-family: $fontopensans;
            }

            &--group-header {
                background: #ebebeb;
                padding: 7px 5px;

                label {
                    display: block;
                    font-family: $fontcuprum;
                    font-size: 17px;
                }
            }
        }
    }

    &__checkbox {
        -webkit-appearance: checkbox;
        -moz-appearance: checkbox;
        appearance: checkbox;
        display: block;
        width: 13px !important;

        &--group-header {
            margin-top: 2px;
        }
    }

    &__checkbox-label {
        display: block;
        width: calc(100% - 13px - 5px);
        margin-left: 5px;
    }

    &__select-button {
        display: block;
        font-family: $fontcuprum;
        font-size: 17px;
        color: #fff;
        background-color: #004D7B;
        width: calc(100% - 26px);
        padding: 10px;
        cursor: pointer;

        &:hover {
            background-color: #006dae;
        }
    }

    &__more {
        color: #0A246A;
        font-size: 13px;
        font-family: $fontopensans;
        padding-left: 18px;
        cursor: pointer;

        &:after {
            content: '>';
            margin-left: 3px;
        }

        &:hover {
            color: lighten(#0A246A, 10%);
            text-decoration: underline;
        }
    }
}