.pagination {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;

    &__item {
        background: #ebebeb;
        width: calc(100% - 150px);
        line-height: 40px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__list {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
    }
    &__link {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: block;
        text-align: center;
        text-decoration: none;
        font-size: 13px;
        color: #3b434d;

        &--left {
            background: url('../img/icon_arrows_pagination@2x.png') 0 0 / 80px 80px no-repeat;

            &:hover {
                background-position: 0 -40px;
            }
        }
        &--right {
            background: url('../img/icon_arrows_pagination@2x.png') -40px 0 / 80px 80px no-repeat;

            &:hover {
                background-position: -40px -40px;
            }
        }
        &:hover {
            color: #e65e26;
        }
        &--separator {
            &:hover {
                color: #3b434d;
            }
        }
        &--active {
            color: #e65e26;
            border-bottom: 2px solid #e65e26;
        }
    }
    &__filter {
        width: 150px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;
        background: #e65e26;
        color: #ffffff;
        text-decoration: none;
        font-size: 13px;
        line-height: 40px;

        &:hover {
            background: #eb914e;
        }

        &--blue {
            background: #3b444d;

            &:hover {
                background: #5597d1;
                color: #004d7b;
            }
        }
    }
}
