.grid {
    &-dis {
        &-row {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 40px;

            @media screen and (max-width: 1220px) {
                justify-content: space-around;
            }
            @media screen and (max-width: 1000px) {
                max-width: 470px;
                margin: auto;
            }

            & img {
                display: block;
            }
            &:last-child,
            & .grid-dis-col-1:last-child,
            & .grid-dis-col-2:last-child,
            & .grid-dis-col-3:last-child,
            & .grid-dis-col-4:last-child,
            & .grid-dis-col-33:last-child,
            & .grid-dis-col-66:last-child,
            & .grid-dis-col-1:nth-child(4n),
            & .grid-dis-col-2:nth-child(2n) {
                margin: 0;
            }
            @media screen and (max-width: 1000px) {
                &:last-child {
                    margin: auto;
                }
            }
        }
        &-col {
            $gutterSize: 10px;
            &-1 {
                width: calc(25% - #{$gutterSize});
                max-width: 230px;
                margin: 0 $gutterSize 0 0;

                &:last-child {
                    width: 25%;
                }

                @media screen and (max-width: 1000px) {
                    width: 100%;
                    max-width: unset;
                    margin-right: 0;

                    &:last-child {
                        width: 100%;
                    }
                }
            }
            &-2 {
                width: calc(50% - #{$gutterSize});
                max-width: 470px;
                margin: 0 $gutterSize 0 0;

                &:last-child {
                    width: 50%;
                }

                @media screen and (max-width: 1000px) {
                    width: 100%;
                    margin-right: 0;
                    max-width: none;

                    &:last-child {
                        width: 100%;
                    }
                }
            }
            &-3 {
                width: calc(75% - #{$gutterSize});
                max-width: 710px;
                margin: 0 $gutterSize 0 0;

                &:last-child {
                    width: 75%;
                }

                @media screen and (max-width: 1220px) {
                    width: 80%;
                    max-width: calc(100% - 240px);
                }
                @media screen and (max-width: 1000px) {
                    width: 100%;
                    max-width: none;
                    margin: 0;
                }
            }
            &-4 {
                width: 100%;
                // max-width: 710px;
                margin: 0 $gutterSize 0 0;

                @media screen and (max-width: 1000px) {
                    width: 100%;
                    max-width: none;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            &-33 {
                width: calc(33% - #{$gutterSize});
                margin: 0 $gutterSize 0 0;

                &:last-child {
                    width: 33%;
                }

                @media screen and (max-width: 1000px) {
                    width: 100%;
                    max-width: none;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            &-66 {
                width: calc(66% - #{$gutterSize});
                margin: 0 $gutterSize 0 0;

                &:last-child {
                    width: 66%;
                }

                @media screen and (max-width: 1000px) {
                    width: 100%;
                    max-width: none;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &-disrb {
        &-row {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 20px;

            @media screen and (max-width: 1220px) {
                margin-right: 10px;
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            }

            & img {
                display: block;
            }
            &:last-child,
            & div:last-child,
            & .grid-disrb-col-1:nth-child(3n),
            & .grid-disrb-col-2:nth-child(2n) {
                margin: 0;
            }
        }
        &-col {
            $gutterSize: 10px;
            &-1 {
                width: calc(33.333333% - #{$gutterSize});
                max-width: 230px;
                margin: 0 #{$gutterSize} 0 0;
            }
            &-2 {
                width: calc(66.66666% - #{$gutterSize});
                max-width: 470px;
                margin: 0 #{$gutterSize} 0 0;
            }
            &-3 {
                width: 100%;
                max-width: 710px;
                margin: 0 #{$gutterSize} 0 0;

                @media screen and (max-width: 1220px) {
                    width: 100%;
                    max-width: 100%;
                    margin: 0;
                }
            }
        }
    }
}
