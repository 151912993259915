.errorblock {
    background: #ebebeb;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px;
    margin-bottom: 10px;

    &__img {
        margin-right: 20px;
    }
    &__text {
        font-size: 32px;
        color: #929292;
        font-family: $fontcuprum;
        font-weight: bold;
        line-height: 35px;
    }
}
